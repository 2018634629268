const config = {
    mui_license: '3a702a384fd8d364b1c0047b8ac04953Tz03MzUyNyxFPTE3MjQ2ODUzMjQwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI=',
    requests_content_type: 'application/json',
    mock_server_api_url: "https://mock.nukleus.technology/",
    use_mock_data: false,
    mock_credentials: {user:'nukleus', password:'nukleus'},
    active_env: "stg",
    envs: {
        "dev":{ 
            domain: "https://dev.nukleus.technology/",
            client_secret: '0FBHhxSg6wn61yeFY9VlkTN1YvoRHfXX/jVOiWS0',
            qa_api_url: "http://127.0.0.1:8000/",
            modules: 4
        },
        "stg":{ 
            domain: "https://stg.nukleus.technology/",
            client_secret: 'ffoiYFzRpsf9Oisxg7SPz6WBjamy8x8JR7xNzsNy',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: 3
        },
        "prod":{
            domain: "https://nukleus.technology/",
            client_secret: 'r/u3aV4bexEujluMZA4LA8HKfDR+0LGXr9iiC3Sj',
            qa_api_url: "https://qa-api.nukleus.technology/",
            modules: 1
        }
    },
    nextcloud_path: "nextcloud/apps/files/?dir=/",
    api_path: "api/v1/",
    task_service: "tasks/",
    client_id: 'frontend',

    ui_settings: {
        drawer_width: 240,
        pagination_page_size_options: [5,10,15,25,50],
        pagination_page_size: 15,
        profile_options: [
            { id:'sigmoidal', name: 'Sigmoidal' },
            { id:'exponential', name: 'Exponential'},
            { id:'linear', name: 'Linear' },
            { id:'hyperbolic', name: 'Hyperbolic' }
        ]
    },

    ui_messages: {
        ai_disclaimer: "Dieser Inhalt wurde automatisch unter anwendung von künstlicher Intelligenz generiert.",
        goal_category: [
            "Neu_Erstellung",
            "Erhaltung_Erneuerung",
            "Revision_Veränderung",
            "Unterhalt_Aufrechterhaltung",
            "Nutzung_Betrieb",
            "Allgemein",
        ],
        usage_category: [
            "Areal",
            "Bildung",
            "Brücke",
            "Büro",
            "Fahrnissbauten",
            "Freiland",
            "Freizeitanlage",
            "Gastronomie",
            "Gewerbe",
            "Hotelerie",
            "Industrie",
            "Kaserne",
            "Komandoposten",
            "Kraftwerk",
            "Kunstbaute",
            "Mischnutzung",
            "Schiessanlage",
            "Schutzanlage",
            "Schutzraum",
            "Spital",
            "Trasse",
            "Tunnel",
            "Wasseraufbereitung",
            "Wohnen",
            "Lärmschutz",
            "Dokument",
            "Technologie",
            "Allgemein",
        ],
    }
}

export default config;