import React, { useState } from 'react';
import { 
  MenuItem, Badge, Button, Breadcrumbs, Link, 
  Typography, TextField, 
  Grid, IconButton, Select, InputLabel, FormControl, Paper,
  Tooltip } from '@mui/material';
//import { BarChart } from '@mui/x-charts/BarChart';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';

import config from '../config';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

import ProjectsLinkToFiles from './ProjectsLinkToFiles';
import TaskList from './ProjectsTaskList';

import { useAppContext } from '../contexts/AppContext';


const convertToDate = (dateString) => {
    const parsedDate = new Date(dateString); 
    return isNaN(parsedDate) ? null : parsedDate;
};

const isDateValid = (startDate, endDate) => {
  // Check if both dates are valid and if endDate is not after startDate
  if (startDate && endDate && isNaN(startDate.getTime()) === false && isNaN(endDate.getTime()) === false && endDate <= startDate) {
    /* alert('Das Enddatum muss nach dem Startdatum liegen.'); */
    return false; // Prevent the update by exiting early
  } 

  return true;
};

const DetailPage = ({ row, onBackClick, onSaveClick, action, extractedData, calculatedData, summary }) => {
    const [editData, setEditData] = useState({
      ...row,
      start_date: convertToDate(row.start_date),
      end_date: convertToDate(row.end_date),
    });
  
    const [tempStartDate, setTempStartDate] = React.useState(editData.start_date);
    const [tempEndDate, setTempEndDate] = React.useState(editData.end_date);
    const [ifValidDates, setIfValidDates] = React.useState(false);
    const [ifValidName, setIfValidName] = React.useState(true);

    const { userProjects } = useAppContext();

    const isValidName = (name) => {
      const regex = /^[a-zA-Z0-9äöüÄÖÜß\s]+$/;
      
      // Function to sanitize the name
      const sanitizeName = (name) => {
        return name.trim().replace(/\s+/g, '_').toLowerCase();
      };
      
      // Sanitize the incoming name
      const sanitizedInputName = sanitizeName(name);
      
      // Map and sanitize project names
      const projectNames = userProjects.map(path => {
        const projectName = path.split('/').pop();
        return sanitizeName(projectName);
      });
      
      return {
        isValid: regex.test(name) && !projectNames.includes(sanitizedInputName),
        isUnique: !projectNames.includes(sanitizedInputName),
        matchesRegex: regex.test(name),
      };
    };
  
    const handleDateChange = (field, value) => {
      
      // Date validation logic
      let start_date_to_validate = null;
      let end_date_to_validate = null;
      if (field === 'start_date'){
        start_date_to_validate = new Date(value);
        end_date_to_validate = new Date(tempEndDate);
      } 
      
      if (field === 'end_date') {
        start_date_to_validate = new Date(tempStartDate);
        end_date_to_validate = new Date(value);
      }
  
      if (isDateValid(start_date_to_validate, end_date_to_validate)) {
        setIfValidDates(true);
      } else {
        setIfValidDates(false);
        alert('Das Enddatum muss nach dem Startdatum liegen.');
      }
  
      setEditData((prev) => ({ ...prev, [field]: value }));
    };
  
    const handleEditChange = (field, value) => {
      // Additional validation for 'name' field
      if (field === 'name') {
        if(!userProjects) {
          alert('Die Projektliste wurde nicht korrekt geladen. Bitte die Projekt Reiter nochmals laden.');
          setIfValidName(false);
          return
        } else {
          const validationResult = isValidName(value);
          if (!validationResult.isValid) {
            console.log('Invalid Name:', value)
            if (!validationResult.isUnique) {
              alert('Der gewählte Name existiert bereits. Bitte wählen Sie einen anderen Namen.');
              setIfValidName(false);
              return
            } else if (!validationResult.matchesRegex) {
              alert('Der Name enthält unzulässige Zeichen. Erlaubt sind Buchstaben, Zahlen, Leerzeichen und deutsche Umlaute.');
              setIfValidName(false);
              return
            }
          } else {
            // Proceed with using the valid name
            setIfValidName(true);
          }
        }
      }

      // Temporarily construct the updated object for validation purposes
      const updatedEditData = { ...editData, [field]: value };
  
      // Date validation logic
      if (field === 'start_date' || field === 'end_date') {
        const startDate = new Date(updatedEditData.start_date);
        const endDate = new Date(updatedEditData.end_date);
  
        // Check if both dates are valid and if endDate is not after startDate
        if (startDate && endDate && isNaN(startDate.getTime()) === false && isNaN(endDate.getTime()) === false && endDate <= startDate) {
          alert('Das Enddatum muss nach dem Startdatum liegen.');
          return; // Prevent the update by exiting early
        }
      }
  
      // If the validation passes or the update is for other fields, proceed to update
      setEditData(updatedEditData);
    };
  
    const isViewAction = action === 'view';
  
    const areRequiredFieldsPresentAndValid = (editData) => {
      const requiredFields = ['name', 'project_class', 'project_category', 'profile', 'start_date', 'end_date'];
      const areFieldsPresent = requiredFields.every(field => editData[field] !== undefined && editData[field] !== '');
      return areFieldsPresent;
    };
  
    const renderViewOrEditField = (label, value, field, type) => {
      if (action === 'view') {
        if (field === 'path') { 
          // When 'path', return the ProjectsLinkToFiles component
          return <ProjectsLinkToFiles path={value} />;
        } else {
          // For other fields in 'view' action, return Typography
          return (
            <Typography variant="body1" style={{ margin: '8px 0' }}>
              <strong>{label}: </strong>{value}
            </Typography>
          );
        }
      } else {
        // For 'edit' action, return TextField
        return (
          <TextField
            label={label}
            value={value}
            type={type === 'number' ? 'number' : 'text'}
            inputProps={{ min: 0, max: 1, step: 0.01 }}
            onChange={(e) => handleEditChange(field, e.target.value)}
            fullWidth
            margin="normal"
            disabled={isViewAction}
          />
        );
      }
    };
    
    const renderDisabledField = (label, value, field, type, isDisabled) => {
      return (
        <TextField
          label={label}
          value={value}
          type={type === 'number' ? 'number' : 'text' }
          inputProps={{ min: 0, max: 1, step: 0.01 }}
          onChange={(e) => handleEditChange(field, e.target.value)}
          fullWidth
         
          disabled={isDisabled}
        />
      );
    };

    const projectProgressPercentage = (calculatedData) => {
      if(calculatedData && calculatedData.phase_completion_percentage !== undefined ) {
        if (calculatedData.phase_completion_percentage === 0) {
          return '0%'
        } else { 
          return calculatedData.phase_completion_percentage.toFixed(2) + '%'
        }
      } else {
        return 'Unbekannt'
      }
    };
  
    const ProjectView = ({ editData, calculatedData }) => {
      return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%',  width: '100%' }}>
          <CardHeader 
            title="Projektinformationen" 
            sx={{ textAlign: 'left', paddingBottom: 0 }}
          />
  
          <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
            {renderViewOrEditField("Name", editData.name, 'name')}
            {renderViewOrEditField("Datenablage Pfad", editData.path, 'path')}
            {renderViewOrEditField("SOLL Phase", editData.predicted_phase, 'predicted_phase')}
            {renderViewOrEditField("SOLL Projektfortschritt", projectProgressPercentage(calculatedData), 'predicted_phase')}
            {/* <Chip label={editData.status} color={editData.status === 'active' ? 'success' : 'warning'} /> */}
            {renderViewOrEditField("Zielkategorie", editData.project_category, 'project_category')}
            {renderViewOrEditField("Nutzungskategorie", editData.project_class, 'project_class')}
            {/* {renderViewOrEditField("Strategische Gewichtung", editData.weight, 'weight')}
            {renderViewOrEditField("Earned Value", editData.earned_value, 'earned_value')} */}
            {renderViewOrEditField("Profil", editData.profile, 'profile')}
            {renderViewOrEditField("Startdatum", editData.start_date.toString(), 'start_date')}
            {renderViewOrEditField("Enddatum", editData.end_date.toString(), 'end_date')}
          </CardContent>
        </Card>
      )
    }
  
    const ProjektStatus = ({ completionCard, undefinedCard, fields, status_summary }) => {
      const getDate = (status_summary) => { 
        if (status_summary && status_summary.date !== undefined) return status_summary.date 
        else return null 
      }

      const getText = (status_summary) => { 
        if (status_summary && status_summary.text !== undefined) return status_summary.text 
        else return null 
      }
      return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%',  width: '100%' }}>
          <CardHeader 
            title="Projektstatus" 
            sx={{ textAlign: 'left', paddingBottom: 0 }}
          />
          <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
            {/* Row for Zusammenfassung */}
            {status_summary && (
              <Paper elevation={3} sx={{ backgroundColor: '#f5f5f5', padding: 1, marginBottom: 4 }}>
                <Typography variant="body2" align="left" sx={{ marginBottom: 1 }}>
                  <AutoAwesomeIcon color="secondary"/>
                  <Tooltip title={config.ui_messages.ai_disclaimer}>
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                  <b>Zusammenfassung</b> aus den letzten Protokoll bearbeitet am <b>{getDate(status_summary)}</b>:
                </Typography>

                <Typography variant="body2" align="left" sx={{ marginBottom: 1 }}>
                  {getText(status_summary)}
                </Typography>  
              </Paper>
            )}
              
            <Grid container spacing={2} alignItems="flex-start">
  
              {/* Fields List */}
              <Grid item xs={4}>
                <Grid container direction="column" spacing={2}>
                  {fields.map((field, index) => (
                    <Grid item key={index}>
                      <Typography variant='body2' style={{ display: 'inline-flex', alignItems: 'center', margin: '4px' }}>
                        {field.label}
                      </Typography>
                      <Badge style={{ ...field.style, display: 'inline-flex', alignItems: 'center', margin: '4px 0' }}
                        badgeContent={field.value}
                        color='primary'
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <TaskAltIcon style={field.style} />
                      </Badge>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
  
              {/* Projektfertigstellung Card */}
              <Grid item align='center' xs={4} sx={{ height: '100%' }}>
                {completionCard && completionCard.value !== undefined && 
                  <Paper elevation={3} sx={{ 
                    backgroundColor: 'white', 
                    padding: 1, 
                    marginBottom: 0, 
                    height: '100%', 
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'  // Distributes children along the main-axis 
                  }}>
                    <Typography variant='body' color="textSecondary" gutterBottom sx={{ padding: 1, flex: '1 0 auto' }}>
                      {completionCard.label}
                    </Typography>
                    <Typography variant="h3" sx={{ padding: 1, flex: '1 0 auto'}}>
                      {completionCard.value}
                    </Typography>
                  </Paper>
                }
              </Grid>

              {/* Projektfertigstellung Card */}
              <Grid item align='center' xs={4} sx={{ height: '100%' }}>
                {undefinedCard && undefinedCard.value !== undefined && 
                  <Paper elevation={3} sx={{
                    backgroundColor: 'white',
                    padding: 1,
                    marginBottom: 0,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'  // Distributes children along the main-axis
                  }}>
                    <Typography variant='body' color="textSecondary" gutterBottom sx={{ padding: 1, flex: '1 0 auto' }}>
                      {undefinedCard.label}
                    </Typography>
                    <Typography variant="h3" sx={{ padding: 1, flex: '1 0 auto' }}>
                      {undefinedCard.value}
                    </Typography>
                  </Paper>
                }
              </Grid>
  
            </Grid>
          </CardContent>
        </Card>
      );
    };
  
    /* const TaskList = ({ dataGridRows }) => {
  
      // Add 'status' attribute to each row item
      const isDelayedStatus = ( status ) => {
        if (status == true) {
          return (
            "vergangene"
          )
        } else if (status == null) {
          return (
            "unbekannt"
          )
        } else {
          return (
            "bevorstehende"
          )
        }
      };

      const getUrlForPath = (project_path, doc_id) => {
        if (project_path !== '' && project_path.length > 1 && /\//.test(project_path)) {
            let modified_path = project_path.substring(1)
            let last_slash_pos = modified_path.lastIndexOf('/')
            if (last_slash_pos !== -1){
              modified_path = modified_path.substring(0, last_slash_pos);
            }
            return config.envs[config.active_env].domain + config.nextcloud_path + modified_path + '&openfile=' + doc_id;
        } else {
            return "";
        }
      };
  
      const dataGridColumns = [
        { field: 'text', headerName: 'Aufgabe', flex:2, },
        { field: 'assignee', headerName: 'Verantwortlicher', flex:0.5,},
        { field: 'due_date', headerName: 'Fälligkeitsdatum', flex:0.5, },
        { field: 'documents', headerName: 'Im Dokument gefunden', flex:1.5, 
          renderCell: (params) => {
           return(   
              <List variant="body2">
                {params.row.documents.map((doc, index) => (   
                    <Link key={index} sx={{padding:1}} target="_blank" href={getUrlForPath(doc.path, doc.document_id)} rel="noreferrer">{doc.path}</Link>                                                 
                ))}
              </List>            
            )
          }
        },
        { field: 'status', headerName: 'Status', flex:0.5,
          renderCell: (params) => {
           return(   
            
            <Chip label={isDelayedStatus(params.row.status)} color={params.row.status === true ? 'default' : (params.row.status === null ? 'warning' : 'success')} /> 
                               
            )
          },
          valueGetter: (params) => isDelayedStatus(params.row.status),
        }
      ];

      // Using row index as a unique identifier
      const getRowId = React.useCallback((row, index) => `${row.text}-${index}`, []);

    
      return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <CardHeader 
            title={
              <>
                <AutoAwesomeIcon color="secondary" sx={{ verticalAlign: 'bottom', marginRight: 1 }} />
                <Tooltip title={config.ui_messages.ai_disclaimer}>
                  <IconButton sx={{ padding: 0, marginRight: 2 }}> 
                    <InfoOutlinedIcon />
                  </IconButton>
                </Tooltip>
                <Typography variant="h6" component="span">
                  Wesentliche Aufgaben als Übersicht
                </Typography>
              </>
            } 
            sx={{ textAlign: 'left', paddingBottom: 0 }}
          
        
          />
          <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
            <DataGridPro 
            width='100%' 
            rows={dataGridRows} 
            columns={dataGridColumns} 
            pageSize={5} 
            getRowId={getRowId}/>
          </CardContent>  
        </Card>
        
      )  
    } */
  
    /* const TopicScores = ({ topics }) => {
      // Render a placeholder or message if topics are empty
      if (!topics || topics.length === 0) {
        return (
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            <CardHeader title="Themenbewertung" sx={{ textAlign: 'left', paddingBottom: 0 }} />
            <CardContent sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Typography variant="subtitle1">
                Keine Daten zur Anzeige
              </Typography>
            </CardContent>
          </Card>
        );
      }
    
      const names = topics.map(item => item.name);
      const scores = topics.map(item => item.score);
      // Assuming @mui/x-charts BarChart component accepts a data prop with the structure [{ name: 'Topic 1', score: 10 }, ...]
      return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
          <CardHeader title="Themenbewertung" sx={{ textAlign: 'left', paddingBottom: 0 }} />
          <CardContent sx={{ flexGrow: 1 }}>
            <BarChart
              series={[{ data: scores }]}
              xAxis={[{ scaleType: 'band', data: names }]}
              width={500}
              height={300}
            />
          </CardContent>
        </Card>
      );
    }; */
    
    const renderView = (editData, calculatedData) => {
      // Define styles for different task types
      const taskStyles = {
          newTasks: { color: 'orange' },
          reoccurringTasks: { color: 'red' },
          totalCompletedTasks: { color: 'green' }
      };

      const getCalculatedValue = (calculatedData, fieldName) => {
        if (calculatedData === null) return 0
        else {
          return calculatedData[fieldName]
        }
      };

      function convertToDecimal(number) {
        // Ensure that both inputs are numbers and total is not zero to avoid division by zero.
        if ( typeof number !== 'number' || number === 0) {
          return 0; // Return 0 or some other fallback value if inputs are invalid
        }

        // The toFixed() method formats a number using fixed-point notation and returns the result as a string.
        return +number.toFixed(2);
      }

      const undefinedCard = () => {
        if (extractedData && extractedData.length > 0) {
          let filtered_data = extractedData.filter(item => !item.due_date)
          let percentage_unkown = filtered_data.length / extractedData.length * 100
          return {
            label: 'Aufgaben/Themen ohne erkannte Fälligkeitsdatum.',
            value: convertToDecimal(percentage_unkown) + '%',
            style: {} // No specific style for percentage
          }
        } else {
          return null
        }
      }

      // Separating "Projektfertigstellung" for special handling
      const completionField = () => {
        if (calculatedData && calculatedData.completion_percentage !== undefined) {
          return {
            label: 'Aufgaben/Themen, deren anerkanntes Fälligkeitsdatum erreicht ist.',
            value: convertToDecimal(calculatedData.completion_percentage) + '%',
            style: {} // No specific style for percentage
          }
        } else {
          return null
        }
      };
  
      // Remaining fields for "Projektstatus"
      const fields = [
        { label: 'vergangene Aufgaben', value: getCalculatedValue(calculatedData, 'total_completed_tasks'), style: taskStyles.totalCompletedTasks },
        { label: 'bevorstehende Aufgaben', value: getCalculatedValue(calculatedData, 'new_tasks'), style: taskStyles.newTasks },
        { label: 'wiederholende Aufgaben', value: getCalculatedValue(calculatedData, 'reoccurring_tasks'), style: taskStyles.reoccurringTasks },
        { label: 'Total Definierte Aufgaben', value: getCalculatedValue(calculatedData, 'total_defined_tasks') }
      ];
  
      return (
        <div style={{ padding: '20px' }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">
  
            {/* Left Column for Fields View */}  
            <Grid item xs={6} style={{ display: 'flex' }}>
              <ProjectView editData={editData} calculatedData={calculatedData}/>
            </Grid>
  
            {/* Right Column for Status */}  
            <Grid item xs={6} style={{ display: 'flex' }}>
              <ProjektStatus completionCard={completionField()} undefinedCard={undefinedCard()} fields={fields} status_summary={summary}/>
            </Grid>
           
          </Grid>
  
          <Grid container spacing={2} justifyContent="space-between" alignItems="stretch" style={{ marginTop: '20px' }}>
  
            {/* DataGridPro column for tasks */}
            {extractedData && extractedData.length > 0 && (
              <Grid item xs={12} style={{ display: 'flex' }}>
                <TaskList dataGridRows={extractedData} />
              </Grid>
            )}
            
            {/* BarChart column */}
            <Grid item xs={6} style={{ display: 'flex' }}>
              {/* <TopicScores topics={editData.topic_contribution}/>   */}    
            </Grid>
  
          </Grid>
        </div>
      );
    };
  
    const renderEdit = (editData) => {
  
      return (
        <div style={{ padding: '20px' }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={4}>
              {renderDisabledField("Name", editData.name, 'name', 'text', action === 'edit')}
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Zielkategorie</InputLabel>
                <Select
                  label="Zielkategorie"
                  value={editData.project_category}
                  disabled={isViewAction}
                  onChange={(e) => handleEditChange('project_category', e.target.value)}
                >
                  {config.ui_messages.goal_category.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                <InputLabel>Nutzungskategorie</InputLabel>
                <Select
                  label="Nutzungskategorie"
                  value={editData.project_class}
                  disabled={isViewAction}
                  onChange={(e) => handleEditChange('project_class', e.target.value)}
                >
                  {config.ui_messages.usage_category.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            {/* Decimal Input Fields with Validation */}
            {/* <Grid item xs={4}>
              {renderViewOrEditField("Strategische Gewichtung", editData.weight, 'weight', 'number')}
            </Grid> */}
            {/* <Grid item xs={4}>
              {renderViewOrEditField("Earned Value", editData.earned_value, 'earned_value', 'number')}
            </Grid> */}
            {/* Profil Dropdown */}
            <Grid item xs={4}>
              <FormControl fullWidth required>
                <InputLabel>Profil</InputLabel>
                <Select
                  label="Profil"
                  value={editData.profile}
                  disabled={isViewAction}
                  onChange={(e) => handleEditChange('profile', e.target.value)}
                >
                  {config.ui_settings.profile_options.map((option, index) => (
                    <MenuItem key={index} value={option.name}>{option.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
  
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
              <Grid item xs={2}>
                <DatePicker
                  required
                  label="Startdatum"
                  disabled={isViewAction}
                  value={tempStartDate}
                  onChange={(newValue) => {
                    setTempStartDate(newValue); // Temporarily set the new value
                    handleDateChange('start_date', newValue); // Attempt to validate and update editData
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item xs={2}>
                <DatePicker
                  required
                  label="Enddatum"
                  disabled={isViewAction}
                  value={tempEndDate}
                  onChange={(newValue) => {
                    setTempEndDate(newValue); // Temporarily set the new value
                    handleDateChange('end_date', newValue); // Attempt to validate and update editData
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
            </LocalizationProvider>
  
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button 
                variant="contained" 
                color="primary" 
                disabled={!areRequiredFieldsPresentAndValid(editData) || isViewAction || !ifValidDates || !ifValidName}
                onClick={handleLocalSaveClick} // Use the local handler 
                style={{ marginRight: '8px' }}>
                Vortfahren
              </Button>
              <IconButton onClick={onBackClick}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
        )
    }
  
    const renderData = (editData, calculatedData) => {
      return action === 'view' ? (
        renderView(editData, calculatedData)
      ) : (
        renderEdit(editData)
      );
    }
  
    // This function will be triggered when 'Save' is clicked
    const handleLocalSaveClick = () => {
  
      // Validate required fields before saving
      if (!editData.profile || !editData.start_date || !editData.end_date  || !editData.name || !editData.project_class || !editData.project_category) {
        alert('Please fill all required fields.');
        return;
      }
  
      const {
        path, // Excluded from spread
        predicted_phase, // Excluded from spread
        distribution, // Excluded from spread
        ...restOfEditData
      } = editData;
  
      // Pass editData to the onSaveClick prop, which can be your updateProject function
      const dataToSave = {
        ...restOfEditData,
        /* weight: editData.weight === '' ? 1 : editData.weight,
        earned_value: editData.earned_value === '' ? 1 : editData.earned_value, */
      };
      onSaveClick(dataToSave);
    };
  
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
          <IconButton onClick={onBackClick}>
            <ArrowBackIcon />
          </IconButton>
          <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}> {/* Enlarge the font size */}
            <Link color="inherit" href="#" onClick={onBackClick}>
              Liste
            </Link>
            <Typography color="textPrimary" style={{ fontWeight: 'bold', fontSize: '1.45rem' }}>{row.name}</Typography> {/* Make it bold */}
          </Breadcrumbs>
          <Tooltip title="Erstellen Sie ein Projekt um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden.">
            <IconButton>
              <InfoOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
        {/* Render detail information here based on the row data */}
        {renderData(editData, calculatedData)}
      </div>
    );
};

export default DetailPage;