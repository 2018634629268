import React from 'react';
import { useAuth } from '../contexts/AuthContext';
import { FormControl, InputLabel, Select, MenuItem, Card, CardContent, CardHeader } from '@mui/material';
import './TenantSelection.css'; // Assuming you're using CSS for styles

const TenantSelection = () => {
  const { userTenants, setCurrentTenant, currentTenant } = useAuth();

  const handleSelectTenant = (event) => {
    const tenantId = event.target.value;
    // Find the selected tenant based on tenantId
    const selectedTenant = userTenants.find(tenant => tenant === tenantId);
    setCurrentTenant(selectedTenant);
  };

  return (
    <div className="tenant-selection-container">
      <Card sx={{ minWidth: 275, maxWidth: 500, margin: 'auto', position: 'relative', transform: 'translateY(-50%)' }}>
        <CardHeader
          title="Tenant Auswählen"
          subheader="Sie sind Mitglied von mehreren Tenants. Bitte wählen Sie den Tenant, dem Sie beitreten möchten."
          titleTypographyProps={{ variant: 'h6' }} // Adjusts the size to more closely match an h2 element in size
          subheaderTypographyProps={{ variant: 'subtitle1' }} // Provides a suitable style for the subtitle
        />
        <CardContent>
          <FormControl fullWidth>
            <InputLabel id="tenant-select-label">Tenant</InputLabel>
            <Select
              labelId="tenant-select-label"
              id="tenant-select"
              value={currentTenant ? currentTenant : ''}
              label="Tenant"
              onChange={handleSelectTenant}
            >
              {userTenants.map((tenant) => (
                <MenuItem key={tenant} value={tenant}>{tenant}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </CardContent>
      </Card>
    </div>
  );
};

export default TenantSelection;
