import * as React from 'react';
import Box from '@mui/material/Box';
import PushPinIcon from '@mui/icons-material/PushPin';
import { Typography, Grid } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Chip from '@mui/material/Chip';
import Highlighter from "react-highlight-words";

import config from '../config';

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

export default function SearchResultDocs(props) {
    const theme = useTheme();
    /* const [selectedIndex, setSelectedIndex] = React.useState(1);
    const handleListItemClick = (
        event,
        index,
    ) => {
        console.log('Selecting Element in Index: ', index);
        setSelectedIndex(index);
    }; */

    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    const formatParseDate = (date) => {
        return `Datei verarbeitet am: ${date}`;
    }

    const formatFormat = (format) => {
        if (format) {
            return `Datei format: ${format.split('.')[1].toUpperCase()}`;
        }
    }

    const formatScore = (score) => {
        return "Relevanz: " + parseFloat(score).toFixed(2);
    };
    
    
    const getUrl = () => {
        if(config.use_mock_data){
            return props.matchObject.source.path;
        } else {
            //let reversed_filepath = props.matchObject.source.path.split("").reverse().join("");
            
            if (props && props.matchObject && props.matchObject.source ) {
                let filepath = props.matchObject.source.path; //"/test_project1/Bautechnik/de_clds_30491.pdf"
                let filepaths_no_file = filepath.split('/').filter(entry => entry.trim() != '').slice(0,-1);
                return config.envs[config.active_env].domain + config.nextcloud_path + filepaths_no_file.join('/') + '&openfile=' + props.matchObject.source.id;
            } else {
                return "";
            }
            
        }
    };

    const getHighlights = (spans) => {
        if (spans ) {
            return spans.map(function(item) {
                return item['text'];
            });
        }
    };

    return (
        <Box sx={{ width: '100%', textAlign:'left', padding: '2em 0.5em 2em 0.5em'}}>       
            <Card>
                <CardHeader
                    sx={{paddingBottom:1}}
                    avatar={
                    <Avatar sx={{ bgcolor: 'black' }} aria-label="recipe">
                        D
                    </Avatar>
                    }
                    action={
                        <Box>
                            <Link sx={{padding:2}}
                            href={ getUrl() } target="_blank">
                                <Typography variant='button' sx={{padding:2, marginBottom:'20px'}}>
                                    Dokument öffnen 
                                </Typography>
                                <OpenInNewIcon />
                            </Link>
                            
                            <ExpandMore
                            expand={expanded}
                            onClick={handleExpandClick}
                            aria-expanded={expanded}
                            aria-label="show more"
                            >
                                <ExpandMoreIcon />
                            </ExpandMore>

                            <IconButton disabled aria-label="pin">
                                <PushPinIcon />
                            </IconButton>
                            
                        </Box>
                    }
                    title={
                        <Box>
                            <Button 
                            onClick={handleExpandClick}
                            variant='text'>
                                <Typography 
                                variant="h6">
                                    <strong>{props.matchObject.file_name}</strong>
                                </Typography>
                            </Button>
                            <Typography 
                            variant="body2"
                            sx={{padding:1}}>
                                {formatParseDate(props.matchObject.creation_date)}
                            </Typography>
                            {/* Chip displaying the client value */}
                            {props.matchObject.client && (
                                <Chip sx={{margin:0.5}} label={props.matchObject.client} />
                            )}
                            {/* Chip displaying the project value */}
                            {props.matchObject.project && (
                                <Chip sx={{margin:0.5}} label={props.matchObject.project} />
                            )}
                            
                        </Box>
                    }
                    subheader={
                        <Box sx={{padding:1}}>
                            {formatScore(props.matchObject.max_score)}
                            {formatFormat(props.matchObject.format)}
                        </Box>
                        
                    }
                >
                </CardHeader>       

                <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent  sx={{ 
                        margin: 1, // default margin
                        [theme.breakpoints.up('sm')]: { margin: 2 },
                        [theme.breakpoints.up('md')]: { margin: 3 },
                        [theme.breakpoints.up('lg')]: { margin: 4 }
                    }}
                    >
                    <div key={0}> {/* Assuming this is a single item, if you have multiple items, map through them */}
                        
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                    Dokument gefunden im Pfad:
                                </Typography>
                                <Typography sx={{whiteSpace: 'pre-wrap'}}>
                                    {props.matchObject.source.path}
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="body2" color="text.secondary">
                                    Suchanfrage gefunden im Kontext:
                                </Typography>
                                <Highlighter
                                    highlightClassName="YourHighlightClass"
                                    searchWords={getHighlights(props.matchObject.spans)}
                                    autoEscape={true}
                                    textToHighlight={props.matchObject.text}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </CardContent>
                </Collapse>
            </Card>
        </Box>  
    );
}
