// src/contexts/AuthContext.js
import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import base64 from 'base-64';
import config from '../config';

const AuthContext = createContext(null);

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
      throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};



export const AuthProvider = ({ children }) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [token, setToken] = useState(null);
    const [userTenants, setTenants] = useState([]);
    const [currentTenant, setCurrentTenant] = useState(null);
    const navigate = useNavigate();
    const clientId = config.client_id
    const redirectUri = `${window.location.origin}`;

    const login = (/* username, password */) => {
        /* if (username === config.mock_credentials.user && password === config.mock_credentials.password) {
            setIsLoggedIn(true);
            return true;
        }
        return false; */

        const authServerUrl = config.envs[config.active_env].domain + 'dex/auth';
        const responseType = 'code';
        const scope = 'openid email profile groups';
        const authUrl = `${authServerUrl}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(scope)}`;

        window.location.href = authUrl;
    };

    const decodeJWT = (encoded_token) => {

        // Split the JWT into its three parts: Header, Payload, and Signature
        const parts = encoded_token.split('.');
        
        // Check if the JWT contains all three parts
        if (parts.length !== 3) {
          throw new Error('Invalid JWT: The token must consist of three parts.');
        }
        
        // Decode the payload from base64 URL to a JSON object
        const payload = parts[1];
        const decodedPayload = JSON.parse(window.atob(payload.replace(/-/g, '+').replace(/_/g, '/')));
        
        return decodedPayload;
      };

    const handleOAuthCallback = async (code) => {
        const tokenEndpoint = config.envs[config.active_env].domain + 'dex/token';
        const clientSecret = encodeURIComponent(config.envs[config.active_env].client_secret);
        const basicAuth = base64.encode(`${clientId}:${clientSecret}`);

        try {
            const response = await fetch(tokenEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization': `Basic ${basicAuth}`,
                },
                body: `grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}`,
            });

            if (response.ok) {
                const data = await response.json();
                const expiresAt = new Date().getTime() + data.expires_in * 1000; // Convert to milliseconds
                setToken({
                    accessToken: data.access_token,
                    expiresAt,
                    tokenType: data.token_type,
                    idToken: data.id_token,
                });
                setIsLoggedIn(true);
                if(data.access_token) {
                    const decoded_jwt = decodeJWT(data.access_token);
                    if(decoded_jwt && decoded_jwt.groups) {
                        setTenants(decoded_jwt.groups);
                    }
                    navigate('/'); // Redirect to home on successful login
                    //console.log('decoded_jwt', decoded_jwt);
                }
                
                
            } else {
                if (response.status == 500) { 
                    throw Error(response.statusText); 
                }
                return ; // will print '200 - ok'
            }
            
        } catch (error) {
            console.error('Login failed:', error);
        }
    };

    const isTokenValid = () => {
        if(token) {
            const now = new Date().getTime();
            console.log('Time now: ', now.toLocaleString())
            const expiresAt = token.expiresAt;
            console.log('Token expiresIn: ', expiresAt.toLocaleString())
/*             const expiresAt = new Date().getTime() + expiresIn * 1000; // Convert to milliseconds and add to current time
            console.log('Converted expiry time: ', expiresAt) */
            const isExpired = now > expiresAt;
            console.log('Token isExpired: ', isExpired)
            return !isExpired;
        }
    };

    const logout = () => {
        setIsLoggedIn(false);
        navigate('/login', { replace: true });
    };

    return (
        <AuthContext.Provider value={{ isLoggedIn, token, login, logout, handleOAuthCallback, isTokenValid, userTenants, setCurrentTenant, currentTenant }}>
            {children}
        </AuthContext.Provider>
    );
};