// src/components/ProtectedRoute.js
import React,  {useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useAppContext } from '../contexts/AppContext';
import TenantSelection from './TenantSelection';

const ProtectedRoute = ({ children }) => {
    const { isLoggedIn, handleOAuthCallback, currentTenant, isTokenValid, token } = useAuth();
    const { userData, getUserData } = useAppContext();
    const navigate = useNavigate();

    useEffect(() => { 
        if (isTokenValid()) {
            /* console.log('Token valid', token); */
            if (currentTenant) {
                console.log('Tenant selected: ', currentTenant);
                if( userData == null) {
                    console.log("Fetching User Data");
                    getUserData(currentTenant);
                } else {
                    console.log("user Data present: ", userData);
                }
            } else {
                console.log("No Tenant selected, please select Tenant.");
            }   
        } else {
            console.log('Token invalid: ', token);
            /* console.log('UserData invalid', userData); */
            navigate('/login'); // Imperative navigation
        }
    }, [token, currentTenant]);

    if (!isLoggedIn) {
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            console.log('Received authorization code:', code);
            handleOAuthCallback(code);
        }
        return <Navigate to="/login" />;
    } else {
        if(!currentTenant) {
            return <TenantSelection />;
          } else {
            return children;
          }
    }

    
};

export default ProtectedRoute;