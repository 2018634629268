import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useAppContext } from '../contexts/AppContext';
import { useNavigate } from 'react-router-dom';

const TenantDropdown = () => {
    const { userTenants, setCurrentTenant, currentTenant } = useAuth();
    const { getUserData, userProjects, getProjectDetailList, activePage, projects } = useAppContext();

    let navigate = useNavigate();

    // Handles changing the current tenant
    const handleChange = (event) => {
        const selected_tenant = event.target.value;
        setCurrentTenant(selected_tenant);
        console.log('Tenant Changed: ', selected_tenant);
        console.log('Getting new project list and data.');
        getUserData(selected_tenant).then((filtered_projects) => {
            //console.log('Active Page: ', activePage);
            if(activePage == 1) {
                getProjectDetailList(selected_tenant, filtered_projects).then((data) => {
                    console.log('Result of function: ', data)
                    console.log('Stored Filtered projects: ', projects)
                    navigate('/projects');
                });
            }
        });
    };

    return (
        <FormControl size="small" sx={{ minWidth: 120 }}>
            {/* <InputLabel id="tenant-select-label">Tenant</InputLabel> */}
            <Select
                /* labelId="tenant-select-label" */
                id="tenant-select"
                value={currentTenant}
                /* label="Tenant" */
                onChange={handleChange}
                variant="outlined"
            >
                {userTenants.map((tenant) => (
                    <MenuItem key={tenant} value={tenant}>
                        {tenant}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default TenantDropdown;
