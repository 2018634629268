import React, { useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { 
  Menu, MenuItem, Button, Breadcrumbs, Link, Box,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
Typography, TextField, 
  Grid, IconButton, /* Select, InputLabel, FormControl, */
  Tooltip } from '@mui/material';
import { styled } from '@mui/system';
/* import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de'; */
import { parse, format } from 'date-fns';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useAppContext } from '../contexts/AppContext';
import config from '../config';

const StyledMenuItem = styled(MenuItem)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  color: color || 'inherit', // Apply color if provided
  '& .icon': {
    marginRight: theme.spacing(1),
    color: color || 'inherit', // Apply color to the icon as well
  },
}));

const CustomContextMenu = ({ anchorEl, onClose, onMenuItemClick }) => {
  const options = [
    { id:'view', label: 'View', icon: <VisibilityIcon /> },
/*     { id:'edit', label: 'Edit', icon: <EditIcon /> }, */
    { id:'delete', label: 'Delete', icon: <DeleteForeverIcon />, color: 'red' }, // Add color property for 'delete'
  ];

  const handleMenuItemClick = (option) => {
    onMenuItemClick(option);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {options.map((option) => (
        <StyledMenuItem
          key={option.label}
          onClick={() => handleMenuItemClick(option)}
          style={option.color ? { color: option.color } : {}} // Apply the color style conditionally
        >
          <div className="icon" style={option.color ? { color: option.color } : {}}>{option.icon}</div> {/* Color the icon as well */}
          <Typography variant="inherit" noWrap>
            {option.label}
          </Typography>
        </StyledMenuItem>
      ))}
    </Menu>
  );
};

const RowContextMenu = ({ row, onMenuItemClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleContextMenuClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleContextMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <CustomContextMenu
        anchorEl={anchorEl}
        onClose={handleContextMenuClose}
        onMenuItemClick={onMenuItemClick}
      />
    </>
  );
};

const ListPage = ({ rows, onRowViewClick, onOptionSelect  }) => {

  const columns = [
    { field: 'id', headerName: 'ID', flex:0.5 },
    { field: 'title', headerName: 'Vorlage ID', flex: 1,},
    /* { field: 'title', headerName: 'Vorlage ID', flex: 1, 
      renderCell: (params) => {
        return(   
          <Typography>{params.row.template.id}</Typography>           
        ) 
      }
    },
    { field: 'template_name', headerName: 'Vorlage Name', flex: 1, 
      renderCell: (params) => {  
        return(   
          <Typography>{params.row.template.name}</Typography>           
        )           
      }
    }, */
    { field: 'creation_date', headerName: 'Erstellungsdatum', flex: 1 },
    /* { field: 'result', headerName: 'Ergebniss', flex:1 }, */
    // ... more columns
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <RowContextMenu row={row} onMenuItemClick={(option) =>  {
          /* console.log(option); */
          onRowViewClick(row);
          onOptionSelect(option.id, row);
        }} />
      ),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <DataGridPro 
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
        }}
        pagination
        pageSizeOptions={config.ui_settings.pagination_page_size_options}
        rows={rows} 
        columns={columns} 
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
        disableRowSelectionOnClick
        disableMultipleSelection 
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'lightgrey', // Apply light grey background to headers
          },
        }}
      />
    </div>
  );
};

const DetailPage = ({ row, onBackClick, onSaveClick, action }) => {
  const [editData, setEditData] = useState({
    ...row,
    start_date: convertToDate(row.start_date),
    end_date: convertToDate(row.end_date),
  });

  const requiredFields = ['name', 'start_date', 'end_date', 'profile'];

  const updateStatus = (data) => {
    // Check if all required fields have values and their length is greater than 0
    const isComplete = requiredFields.every(field => data[field] && data[field].toString().trim().length > 0);
    return isComplete ? 'published' : 'draft';
  };
  
  
  const handleEditChange = (field, value) => {
    // Update field value
    setEditData(prevState => ({ ...prevState, [field]: value }));
    setEditData(prevState => ({ ...prevState, status: updateStatus(prevState) }));
  };

  const isViewAction = action === 'view';

  const renderViewOrEditField = (label, value, field, type) => {
    return action === 'view' ? (
      <Typography variant="body1" style={{ margin: '8px 0' }}>
        <strong>{label}: </strong>{value}
      </Typography>
    ) : (
      <TextField
        label={label}
        value={value}
        type={type == 'number' ? 'number' : 'text' }
        inputProps={{ min: 0, max: 1, step: 0.01 }}
        onChange={(e) => handleEditChange(field, e.target.value)}
        fullWidth
        margin="normal"
        disabled={isViewAction}
      />
    );
  };

  const hasResponseToUser = (result) => {
    // Attempt to access result.response_to_user directly
    // The optional chaining operator ?. prevents errors if result is undefined/null
    // The nullish coalescing operator ?? returns false if the left-hand side is null or undefined
    return result?.response_to_user ?? false;
  };

  const renderResult = (result) => {

    if(hasResponseToUser(result)) {
      return result.response_to_user
    } else {
      // Return the original result if it's not JSON or doesn't match expected structure
      return result;
    }
    
  };

  const ReportView = ({ editData }) => {
    return (
      <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%',  width: '100%' }}>
        <CardHeader 
          title="Berichtdetails" 
          sx={{ textAlign: 'left', paddingBottom: 0 }}
        />
        <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
          {renderViewOrEditField("Vorlage ID", editData.id, 'id')}
          {renderViewOrEditField("Vorlage Name", editData.title, 'id')}
          {renderViewOrEditField("Erstellungsdatum", editData.creation_date.toString(), 'creation_date')}
          {/* Render reference_documents */}
          <Typography variant="body1" style={{ margin: '8px 0' }}>
            <strong>Daten Quellen:</strong>
            <Box component="ul" sx={{ pl: 2 }}>
              {editData.reference_documents.map((doc, index) => (
                <li key={index}>
                  <Typography variant="body2">{doc.title}</Typography>
                  {/* If you want to make it clickable, ensure you handle this securely */}
                </li>
              ))}
            </Box>
          </Typography>
          {/* {renderViewOrEditField("Ergebniss", JSON.stringify(editData.result.response_to_user), 'result')} */}
          {/* Adapt the rendering of Ergebniss based on the content of editData.result */}
          <Typography variant="body1" style={{ margin: '8px 0' }}>
            <strong>Ergebniss:</strong>
            <pre style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>{renderResult(editData.result)}</pre>
          </Typography>
          

        </CardContent>
      </Card>
    )
  }

  const renderView = (editData) => {
    return (
      <div style={{ padding: '20px' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">

          {/* Left Column for Fields View */}  
          <Grid item xs={12} style={{ display: 'flex' }}>
            <ReportView editData={editData}/>
          </Grid>
         
        </Grid>

      </div>
    );
};

  
  const renderEdit = (editData) => {
    return (
      <div style={{ padding: '20px' }}>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            {/* {renderViewOrEditField("Name", editData.name, 'name')} */}
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'left' }}>
            {/* {renderViewOrEditField("Datenablage Pfad", editData.root_path, 'root_path')} */}
          </Grid>
          <Grid item xs={4}>

          </Grid>
          {/* Decimal Input Fields with Validation */}
          <Grid item xs={4}>
            {renderViewOrEditField("Strategische Gewichtung", editData.weight, 'weight', 'number')}
          </Grid>
          <Grid item xs={4}>
            {renderViewOrEditField("Earned Value", editData.earned_value, 'earned_value', 'number')}
          </Grid>
          {/* Profil Dropdown */}
          <Grid item xs={6}>
          </Grid>

          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <Button 
              variant="contained" 
              color="primary" 
              disabled={isViewAction}
              /*  onClick={onSaveClick} */
              onClick={handleLocalSaveClick} // Use the local handler 
              style={{ marginRight: '8px' }}>
              Save
            </Button>
            <IconButton onClick={onBackClick}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </div>
      )
  }

  const renderData = (editData) => {
    return action === 'view' ? (
      renderView(editData)
    ) : (
      renderEdit(editData)
    );
  }

  // This function will be triggered when 'Save' is clicked
  const handleLocalSaveClick = () => {

    // Validate required fields before saving
    if (!editData.profile || !editData.start_date || !editData.end_date) {
      alert('Please fill all required fields.');
      return;
    }

    // Pass editData to the onSaveClick prop, which can be your updateReportInstance function
    const dataToSave = {
      ...editData,
      start_date: convertToDateString(editData.start_date),
      end_date: convertToDateString(editData.end_date),
      weight: editData.weight === '' ? 1 : editData.weight,
      earned_value: editData.earned_value === '' ? 1 : editData.earned_value,
    };
    onSaveClick(dataToSave);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <IconButton onClick={onBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}> {/* Enlarge the font size */}
          <Link color="inherit" href="#" onClick={onBackClick}>
            Liste
          </Link>
          <Typography color="textPrimary" style={{ fontWeight: 'bold', fontSize: '1.45rem' }}>{row.name}</Typography> {/* Make it bold */}
        </Breadcrumbs>
        <Tooltip title="Erstellen Sie ein Bericht um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden.">
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
      {/* Render detail information here based on the row data */}
      {renderData(editData)}
    </div>
  );
};

// DeleteConfirmationModal component
const DeleteConfirmationModal = ({ open, onClose, onConfirm, rowId }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onConfirm(rowId)} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const convertToDate = (dateString) => {
  // Parse the date from "DD.MM.YYYY" format
  const parsedDate = parse(dateString, 'dd.MM.yyyy', new Date());
  return isNaN(parsedDate) ? null : parsedDate;
};

const convertToDateString = (date) => {
  return date ? format(date, 'dd.MM.yyyy') : '';
};

export default function ReportInstancesGrid() {
    const { reportInstances, getReportInstancesList, reportInstancesLoading, removeReportInstance } = useAppContext();
    const [selectedRow, setSelectedRow] = useState(null);
    const [action, setAction] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteRowId, setDeleteRowId] = useState(null); // State to store the ID of the row to be deleted

    
    const handleRowViewClick = (row) => {
      setSelectedRow(row);
      console.log('selected row: ', row.id);
    };

    const handleOptionSelect = (optionId, row) => {
      setAction(optionId);
      setSelectedRow(row);
      if (optionId === 'delete') {
        setDeleteModalOpen(true);
        console.log('selected row Delete: ', row);
        setDeleteRowId(row.id); // Store the row ID for deletion
      } else {
        console.log('selected row: ', row, ' for option ', optionId);
      }
    };
  
    const handleBackClick = () => {
      setSelectedRow(null);
      setAction(null);
      console.log('selected row: ', null);
    };

    const handleConfirmDelete = () => {
      if (deleteRowId) {
        removeReportInstance(deleteRowId).then(() => {
          // After deletion, refresh the list or handle the UI update
          getReportInstancesList();
        });
      }
      setDeleteModalOpen(false);
      setAction(null);
      setSelectedRow(null);
    };

    return (
      <div>
        {selectedRow && action !== 'delete' ? (
          <DetailPage
          row={selectedRow}
          onBackClick={handleBackClick}
          /* onSaveClick={handleSaveClick} */
          action={action}
          /* onCancelEdit={handleCancelEdit} */
        />
        ) : (
          <ListPage 
            rows={reportInstances}
            onRowViewClick={handleRowViewClick} 
            onOptionSelect={ (optionId, row) => handleOptionSelect(optionId, row) }
            /* onAddNewProject={handleAddNewProject} */
          />
        )}
        <DeleteConfirmationModal
          open={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          rowId={deleteRowId}
        />
      </div>
    );
}