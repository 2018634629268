import React, { useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { Menu, MenuItem, Button,Typography, IconButton, Link } from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ProjectsLinkToFiles from './ProjectsLinkToFiles';
import config from '../config';

const StyledMenuItem = styled(MenuItem)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  color: color || 'inherit', // Apply color if provided
  '& .icon': {
    marginRight: theme.spacing(1),
    color: color || 'inherit', // Apply color to the icon as well
  },
}));

const CustomContextMenu = ({ anchorEl, onClose, onMenuItemClick }) => {
  const options = [
    { id:'view', label: 'View', icon: <VisibilityIcon /> },
    { id:'edit', label: 'Edit', icon: <EditIcon /> },
    { id:'delete', label: 'Delete', icon: <DeleteForeverIcon />, color: 'red' }, // Add color property for 'delete'
  ];

  const handleMenuItemClick = (option) => {
    onMenuItemClick(option);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {options.map((option) => (
        <StyledMenuItem
          key={option.label}
          onClick={() => handleMenuItemClick(option)}
          style={option.color ? { color: option.color } : {}} // Apply the color style conditionally
        >
          <div className="icon" style={option.color ? { color: option.color } : {}}>{option.icon}</div> {/* Color the icon as well */}
          <Typography variant="inherit" noWrap>
            {option.label}
          </Typography>
        </StyledMenuItem>
      ))}
    </Menu>
  );
};

const RowContextMenu = ({ row, onMenuItemClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleContextMenuClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleContextMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <CustomContextMenu
        anchorEl={anchorEl}
        onClose={handleContextMenuClose}
        onMenuItemClick={onMenuItemClick}
      />
    </>
  );
};

const ListPage = ({ rows, onRowViewClick, onOptionSelect, onAddNewProject  }) => {
    // Using row index as a unique identifier
    const getRowId = (row, index) => `${row.name}-${index}`;

    /* const getUrlForPath = (props) => {
        if (props && props.row && props.row.path ) {
            return config.envs[config.active_env].domain + config.nextcloud_path + props.row.path;
        } else {
            return "";
        }
    }; */

    /* const handleButtonClick = () => {
      // Assuming getUrlForPath returns a URL string
      const url = getUrlForPath(params);
      window.open(url, '_blank');
    }; */
  /* const getStatusChip = (status) => {
    let color = 'default';
    switch (status) {
      case 'active':
        color = 'success';
        break;
      case 'inactive':
        color = 'warning';
        break;
      case 'inactive':
        color = 'info';
        break;
      // Add more cases as needed
    }
    return <Chip label={status} color={color} />;
  }; */

  const columns = [
    { field: 'id', headerName: 'ID', flex:0.5 },
    { field: 'name', headerName: 'Name', flex:1, },
    { field: 'path', headerName: 'Dokumentablage', flex:1.5, 
      renderCell: (params) => ( 
        <ProjectsLinkToFiles path={params.row.path}/>
      ),
    },
    { field: 'project_category', headerName: 'Zielkategorie', flex:0.75, },
    { field: 'project_class', headerName: 'Nutzungskategorie', flex:0.75, },
    { field: 'start_date', headerName: 'Startdatum', flex:0.5, },
    { field: 'end_date', headerName: 'Enddatum', flex:0.5, },
    { field: 'profile', headerName: 'Profil', flex:0.5, }, 
    /* { field: 'status', headerName: 'Status', flex:1, 
      renderCell: (params) => getStatusChip(params.row.status) }, */
    {
      field: 'actions',
      headerName: 'Actions',
      width: 100,
      sortable: false,
      renderCell: ({ row }) => (
        <RowContextMenu row={row} onMenuItemClick={(option) =>  {
          /* console.log(option); */
          /* onRowViewClick(row); */
          onOptionSelect(option.id, row);
        }} />
      ),
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
        <Button variant="contained" color="primary" onClick={onAddNewProject}>
          Projekt Erstellen
        </Button>
      </div>
      <DataGridPro 
        getRowId={(row) => getRowId(row)}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
        }}
        pagination
        pageSizeOptions={config.ui_settings.pagination_page_size_options}
        rows={rows} 
        columns={columns} 
        slots={{ toolbar: GridToolbar }}
        checkboxSelection
        disableRowSelectionOnClick
        disableMultipleSelection 
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: 'lightgrey', // Apply light grey background to headers
          },
        }}
      />
    </div>
  );
};

export default ListPage;
