import React, { useRef, useState, useEffect } from 'react';
import { Button, Box, Chip, Typography } from '@mui/material';
import { useAppContext } from '../contexts/AppContext';

function MultipleFileUploader({ editData, onFilesChange }) {
  const [newFiles, setNewFiles] = useState([]);
  const [referenceDocuments, setReferenceDocuments] = useState([]);
  const fileInputRef = useRef(null);

  const { uploadReportDocs, clearReportDocs } = useAppContext();

  // Initialize referenceDocuments with editData.reference_documents
  useEffect(() => {
    setReferenceDocuments(editData.reference_documents || []);
  }, [editData.reference_documents]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    setNewFiles(prev => [...prev, ...files]);
  };

  const handleRemoveNewFile = (indexToRemove) => {
    const updatedNewFiles = newFiles.filter((_, index) => index !== indexToRemove);
    setNewFiles(updatedNewFiles);
  };

  const handleClearExistingFiles = async () => {
    try {
      const response = await clearReportDocs(editData.title);
      console.log(response);
      setReferenceDocuments([]);
      onFilesChange([]);
    } catch (error) {
      console.error('Failed to clear files', error);
    }
  };

  const handleUploadFiles = async () => {
    try {
      const updatedDocuments = await uploadReportDocs(editData.title, newFiles);
      const combinedDocuments = [...referenceDocuments, ...updatedDocuments];
      onFilesChange(combinedDocuments);
      setReferenceDocuments(combinedDocuments);
      setNewFiles([]); // Clear new files after upload
    } catch (error) {
      console.error('Failed to upload files', error);
    }
  };

  return (
    <Box sx={{ my: 1 }}>
      <Typography variant="h6" align="left" gutterBottom>
        Referenz Dokumenten
      </Typography>
      <Typography variant="subtitle1" align="left" gutterBottom sx={{ mb: 2 }}>
        Wissensbasen und Ressourcen, auf die im Scenario Bezug genommen wird.
      </Typography>

      <Box display="flex" justifyContent="flex-end" alignItems="center" gap={2} sx={{ mb: 2 }}>
        <label htmlFor="file-input">
          <Button variant="contained" component="span">
            Add Files
          </Button>
        </label>
        <Button variant="contained" onClick={handleUploadFiles} disabled={!newFiles.length}>
          Upload to Server
        </Button>
        <Button variant="outlined" onClick={handleClearExistingFiles} disabled={!referenceDocuments.length}>
          Clear Existing Files
        </Button>
        <input
          accept="*"
          style={{ display: 'none' }}
          id="file-input"
          type="file"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
        />
      </Box>

      {referenceDocuments.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', p: 1, width: '100%', mb: 2 }}>
          <Typography variant="subtitle2" gutterBottom>
            Existing Documents
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {referenceDocuments.map((doc, index) => (
              <Chip
                key={index}
                label={doc.name || doc.title}
                variant="outlined"
                color="primary"
              />
            ))}
          </Box>
        </Box>
      )}

      {newFiles.length > 0 && (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', border: '1px solid rgba(0, 0, 0, 0.23)', borderRadius: '4px', p: 1, width: '100%' }}>
          <Typography variant="subtitle2" gutterBottom>
            New Files to Upload
          </Typography>
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {newFiles.map((file, index) => (
              <Chip
                key={index}
                label={file.name}
                onDelete={() => handleRemoveNewFile(index)}
                variant="outlined"
                color="secondary"
              />
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default MultipleFileUploader;
