import React, { useState } from 'react';
import { DataGridPro, GridToolbar } from '@mui/x-data-grid-pro';
import { 
  Menu, MenuItem, Button, Breadcrumbs, Link, 
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, 
  Chip, Typography, TextField, Grid, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import { parse, format } from 'date-fns';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutlined';
import CircularProgress from '@mui/material/CircularProgress';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LinearProgressIndeterminate from './LinearProgressIndeterminate';

import EditDetailReportTemplate from './ReportTemplatesEditDetail';
import { useAppContext } from '../contexts/AppContext';
import config from '../config';

const StyledMenuItem = styled(MenuItem)(({ theme, color }) => ({
  display: 'flex',
  alignItems: 'center',
  color: color || 'inherit', // Apply color if provided
  '& .icon': {
    marginRight: theme.spacing(1),
    color: color || 'inherit', // Apply color to the icon as well
  },
}));

const CustomContextMenu = ({ anchorEl, onClose, onMenuItemClick }) => {
  const options = [
    /* { id:'view', label: 'View', icon: <VisibilityIcon /> }, */
    { id:'edit', label: 'Edit', icon: <EditIcon /> },
    { id:'delete', label: 'Delete', icon: <DeleteForeverIcon />, color: 'red' }, // Add color property for 'delete'
  ];

  const handleMenuItemClick = (option) => {
    onMenuItemClick(option);
    onClose();
  };

  return (
    <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
      {options.map((option) => (
        <StyledMenuItem
          key={option.label}
          onClick={() => handleMenuItemClick(option)}
          style={option.color ? { color: option.color } : {}} // Apply the color style conditionally
        >
          <div className="icon" style={option.color ? { color: option.color } : {}}>{option.icon}</div> {/* Color the icon as well */}
          <Typography variant="inherit" noWrap>
            {option.label}
          </Typography>
        </StyledMenuItem>
      ))}
    </Menu>
  );
};

const RowContextMenu = ({ row, onMenuItemClick }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleContextMenuClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleContextMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleContextMenuClick}>
        <MoreVertIcon />
      </IconButton>
      <CustomContextMenu
        anchorEl={anchorEl}
        onClose={handleContextMenuClose}
        onMenuItemClick={onMenuItemClick}
      />
    </>
  );
};

const ListPage = ({ rows, onRowViewClick, onOptionSelect, onAddNewReport, onCreateReport, qaReportCreationLoading, processingRowId  }) => {
  const getStatusChip = (status) => {
    let color = 'default';
    switch (status) {
      case 'published':
        color = 'success';
        break;
      case 'draft':
        color = 'warning';
        break;
      case 'inactive':
        color = 'info';
        break;
      // Add more cases as needed
    }
    return <Chip label={status} color={color} />;
  };

  const columns = [
    { field: 'id', headerName: 'ID', flex:0.5 },
    { field: 'title', headerName: 'Titel', flex:1, },
    {
      field: 'project',
      headerName: 'Project',
      flex:1,
      renderCell: (params) => {
        return(
          params.row.project.name
        )
      }
    },
    { field: 'description', headerName: 'Beschreibung', flex:1, },
    { field: 'start_date', headerName: 'Startdatum', flex:1, },
    { field: 'end_date', headerName: 'Enddatum', flex:1, },
    { field: 'status', headerName: 'Status', flex:1, 
      renderCell: (params) => getStatusChip(params.row.status) },
      {
        field: 'actions',
        headerName: 'Actions',
        width: 150,
        sortable: false,
        renderCell: ({ row }) => (
          <>
            <RowContextMenu row={row} onMenuItemClick={(option) => {
              onRowViewClick(row);
              onOptionSelect(option.id, row);
            }} />
            <Tooltip title="Bericht ausführen" placement="top">
              <span> {/* Wrap IconButton in a span to support disabled button tooltips */}
                <IconButton
                  onClick={() => onCreateReport(row)}
                  aria-label="trigger-report"
                  size="small"
                  disabled={qaReportCreationLoading}
                >
                  {qaReportCreationLoading ? <CircularProgress size={24} /> : <PlayCircleOutlineIcon color="primary"/>}
                </IconButton>
              </span>
            </Tooltip>
          </>
        ),
      }
  ];

  const CustomGrid = () => {
    if (qaReportCreationLoading) {
      return (
      <div style={{ width: '100%' }}>
        <LinearProgressIndeterminate isLoading={qaReportCreationLoading}/>
      </div>)
    } else {
      return (
        <div style={{ width: '100%' }}>
          <DataGridPro 
            initialState={{
              columns: {
                columnVisibilityModel: {
                  id: false,
                },
              },
              pagination: { paginationModel: { pageSize: config.ui_settings.pagination_page_size } },
            }}
            pagination
            pageSizeOptions={config.ui_settings.pagination_page_size_options}
            rows={rows} 
            columns={columns} 
            slots={{ toolbar: GridToolbar }}
            checkboxSelection
            disableRowSelectionOnClick
            disableMultipleSelection 
            sx={{
              
              '& .MuiDataGrid-columnHeaders': {
                backgroundColor: 'lightgrey',
              },
            }}
          />
        </div>
      );
    }
  }

  return (
    <div style={{ width: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 16 }}>
        <Button variant="contained" color="primary" onClick={onAddNewReport} disabled={qaReportCreationLoading}>
          neue Vorlage
        </Button>
      </div>
      <CustomGrid/>
    </div>
  );
};

const DetailPage = ({ row, onBackClick, onSaveClick, action }) => {
    const [editData, setEditData] = useState({
      ...row,
      start_date: convertToDate(row.start_date),
      end_date: convertToDate(row.end_date),
    });

    const requiredFields = ['name', 'start_date', 'end_date', 'profile'];
    const isViewAction = action === 'view';

    const updateStatus = (data) => {
      // Check if all required fields have values and their length is greater than 0
      const isComplete = requiredFields.every(field => data[field] && data[field].toString().trim().length > 0);
      return isComplete ? 'published' : 'draft';
    };
    
    const handleEditChange = (field, value) => {
      // Update field value
      setEditData(prevState => ({ ...prevState, [field]: value }));
      setEditData(prevState => ({ ...prevState, status: updateStatus(prevState) }));
    };

    const renderViewOrEditField = (label, value, field, type, disabled=false) => {
      return action === 'view' ? (
        <Typography variant="body1" style={{ margin: '8px 0' }}>
          <strong>{label}: </strong>{value}
        </Typography>
      ) : (
        <TextField
          label={label}
          value={value}
          type={type == 'number' ? 'number' : 'text' }
          inputProps={{ min: 0, max: 1, step: 0.01 }}
          onChange={(e) => handleEditChange(field, e.target.value)}
          fullWidth
          margin="normal"
          disabled={disabled ? disabled : isViewAction}
        />
      );
    };

    const ReportTemplateView = ({ editData }) => {
      return (
        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%',  width: '100%' }}>
          <CardHeader 
            title={editData.title} 
            sx={{ textAlign: 'left', paddingBottom: 0 }}
          />
          <CardContent sx={{ textAlign: 'left', flexGrow: 1, '&:last-child': { paddingBottom: '16px' } }}>
            {renderViewOrEditField("Beschreibung", editData.description, 'description')}
            <Chip label={editData.status} color={editData.status === 'published' ? 'success' : 'warning'} />
            {renderViewOrEditField("Projekt", editData.project.name, 'project')}
            {renderViewOrEditField("Startdatum", editData.start_date.toString(), 'start_date')}
            {renderViewOrEditField("Enddatum", editData.end_date.toString(), 'end_date')}
            // Check if `recurrence` and its nested properties exist before rendering the fields
            {editData.recurrence && editData.recurrence.frequency && editData.recurrence.frequency.value &&
              renderViewOrEditField("Wiederholungsfrequenz", editData.recurrence.frequency.value, 'frequency', true)
            }

            {editData.recurrence && editData.recurrence.interval && editData.recurrence.interval.value &&
              renderViewOrEditField("Interval", editData.recurrence.interval.value, 'interval', true)
            }

            {editData.recurrence && editData.recurrence.weekday_of_the_month && editData.recurrence.weekday_of_the_month.value &&
              editData.recurrence.weekday_of_the_month.value.occurrence && editData.recurrence.weekday_of_the_month.value.weekday &&
              renderViewOrEditField("Tag", editData.recurrence.weekday_of_the_month.value.occurrence + editData.recurrence.weekday_of_the_month.value.weekday, 'weekday_of_the_month', true)
            }

            
            {/*Wissens*/}
            <Typography variant='h6'>Wissens</Typography>
            {editData.reference_knowledge.map((field, index) => (
              field.title
            ))}

            <Typography variant='h6'>Vorlagen</Typography>
            {editData.reference_layouts.map((field, index) => (
              field.title
            ))}

            <Typography variant='h6'>Definitionen</Typography>
            {editData.definitions.map((field, index) => (
              field
            ))}

            <Typography variant='h6'>Regeln</Typography>
            {editData.rules.map((field, index) => (
                field
            ))}

            <Typography variant='h6'>Aktionen</Typography>
            {editData.actions.map((field, index) => (
              field.title
            ))}

            <Typography variant='h6'>Shritte</Typography>
            {editData.instructions.map((field, index) => (
              field
            ))}

            <Typography variant='h6'>Beispiele</Typography>
            {editData.examples.map((field, index) => (
              field
            ))}
          </CardContent>
        </Card>
      )
    }

    const renderView = (editData) => {
    
      return (
        <div style={{ padding: '20px' }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="stretch">

            {/* Left Column for Fields View */}  
            <Grid item xs={12} style={{ display: 'flex' }}>
              <ReportTemplateView editData={editData}/>
            </Grid>
          
          </Grid>
        </div>
      );
    };

    const renderData = (editData) => {
      return action === 'view' ? (
        renderView(editData)
      ) : (
        <EditDetailReportTemplate editData={editData} handleLocalSaveClick={handleLocalSaveClick} handleEditChange={handleEditChange} onBackClick={onBackClick}/>
    );
  }

  // This function will be triggered when 'Save' is clicked
  const handleLocalSaveClick = () => {

    // Validate required fields before saving
    if (!editData.start_date || !editData.end_date) {
      alert('Please fill all required fields.');
      return;
    }

    // Pass editData to the onSaveClick prop, which can be your updateReport function
    const dataToSave = {
      ...editData,
      start_date: convertToDateString(editData.start_date),
      end_date: convertToDateString(editData.end_date),
      weight: editData.weight === '' ? 1 : editData.weight,
      earned_value: editData.earned_value === '' ? 1 : editData.earned_value,
    };
    onSaveClick(dataToSave);
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <IconButton onClick={onBackClick}>
          <ArrowBackIcon />
        </IconButton>
        <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}> {/* Enlarge the font size */}
          <Link color="inherit" href="#" onClick={onBackClick}>
            Liste
          </Link>
          <Typography color="textPrimary" style={{ fontWeight: 'bold', fontSize: '1.45rem' }}>{row.name}</Typography> {/* Make it bold */}
        </Breadcrumbs>
        <Tooltip title="Erstellen Sie ein Projekt um Themen, Aufgaben und Dokumente zu sammeln und analysieren lassen. Alle Projekteinstellungen können hier bearbeitet werden.">
          <IconButton>
            <InfoOutlinedIcon />
          </IconButton>
        </Tooltip>
      </div>
      {/* Render detail information here based on the row data */}
      {renderData(editData)}
    </div>
  );
};

// DeleteConfirmationModal component
const DeleteConfirmationModal = ({ open, onClose, onConfirm, rowId }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={() => onConfirm(rowId)} color="primary" autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const convertToDate = (dateString) => {
  // Parse the date from "DD.MM.YYYY" format
  const parsedDate = parse(dateString, 'dd.MM.yyyy', new Date());
  return isNaN(parsedDate) ? null : parsedDate;
};

const convertToDateString = (date) => {
  return date ? format(date, 'dd.MM.yyyy') : '';
};

export default function ReportTemplatesGrid() {
    const { reportTemplates, getReportTemplatesList, createReportTemplate, updateReportTemplate, removeReportTemplate, createReportInstance, fetchReportData, getReportInstancesList, qaReportCreationLoading} = useAppContext();
    const [selectedRow, setSelectedRow] = useState(null);
    const [action, setAction] = useState(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
    const [deleteRowId, setDeleteRowId] = useState(null); // State to store the ID of the row to be deleted
    const [processingRowId, setProcessingRowId] = useState(null);

    const handleTriggerCreateReport = async (row) => {
      console.log('Scenario Ausführen für: ', row.id);
      setProcessingRowId(row.id); // Track the row being processed
      /* setQaReportCreationLoading(true); */
      try {
        // Directly await the promise returned by postCreateReport
        const response = await fetchReportData(row.title, row.model, row.response_format, row.reference_documents, row.system_instructions, row.user_instructions);
        console.log("Fetched Report data for creation: ", response);
        if(response != null) {
          await createReportInstance(response);
          getReportInstancesList(); // Refresh the list
        }
        // Return the response so it can be used by the caller of fetchReportData
        return response;
      } catch (error) {
        // Log and re-throw the error to allow the caller to handle it
        console.error(error);
        throw error;
      } finally {
        /* setQaReportCreationLoading(false); */
        setProcessingRowId(null); // Reset processing state
      }
    };

    const handleAddNewReportTemplate = () => {
      // Set the selectedRow to a new empty report template structure
      setSelectedRow({
        status: 'draft', // Default status set to 'draft'
        title: '',
        description: '',
        project: '',
        start_date: '',
        end_date: '',
        profile: '',
        reference_knowledge:[],
        reference_layouts:[],
        actions:[],
        instructions:[],
        definitions:[],
        rules:[],
        examples:[],
        
        // ... any other fields you need
      });
      setAction('edit'); // Set action to 'edit' to use the DetailPage for creating a new report template
    };
    
    const handleRowViewClick = (row) => {
      setSelectedRow(row);
      console.log('selected row: ', row.id);
    };

    const handleOptionSelect = (optionId, row) => {
      setAction(optionId);
      setSelectedRow(row);
      if (optionId === 'delete') {
        setDeleteModalOpen(true);
        console.log('selected row Delete: ', row);
        setDeleteRowId(row.id); // Store the row ID for deletion
      } else {
        console.log('selected row: ', row, ' for option ', optionId);
      }
    };
  
    const handleBackClick = () => {
      setSelectedRow(null);
      setAction(null);
      //console.log('selected row: ', null);
    };

    const handleSaveClick = (editData) => {
      // Use your updateReport function from the context
      console.log('Saving data', editData);
      if(editData.id) {
        // Upload References to new endpoint
        // use response to store it in report template:
        updateReportTemplate(editData.id, editData)
        .then(() => {
          // Assuming updateReport returns a Promise, refresh the reports list after the update is successful
          getReportTemplatesList(); // This function should re-fetch the updated list of reports
        })
        .catch(error => {
          // Handle any errors here
          console.error('Failed to update report', error);
        });
        
      } else {
        
        createReportTemplate(editData)
        .then(() => {
          // Assuming updateReport returns a Promise, refresh the reports list after the update is successful
          getReportTemplatesList(); // This function should re-fetch the updated list of reports
        })
        .catch(error => {
          // Handle any errors here
          console.error('Failed to add report', error);
        });
      }
      
    
      // Clear the action and selected row to go back to the list view
      setAction(null);
      setSelectedRow(null);
    };

    const handleConfirmDelete = () => {
      if (deleteRowId) {
        removeReportTemplate(deleteRowId).then(() => {
          // After deletion, refresh the list or handle the UI update
          getReportTemplatesList();
        });
      }
      setDeleteModalOpen(false);
      setAction(null);
      setSelectedRow(null);
    };

    const handleCancelEdit = () => {
      // Logic for cancel edit (show warning modal)
      console.log('Edit cancelled');
      // Set a state to open the warning modal
    };

    return (
      <div>
        {selectedRow && action !== 'delete' ? (
          <DetailPage
          row={selectedRow}
          onBackClick={handleBackClick}
          onSaveClick={handleSaveClick}
          action={action}
          onCancelEdit={handleCancelEdit}
        />
        ) : (
          <ListPage 
            rows={reportTemplates}
            onRowViewClick={handleRowViewClick} 
            onOptionSelect={ (optionId, row) => handleOptionSelect(optionId, row) }
            onAddNewReport={handleAddNewReportTemplate}
            onCreateReport={(row) => handleTriggerCreateReport(row)}
            qaReportCreationLoading={qaReportCreationLoading}
            processingRowId={processingRowId}
          />
        )}
        <DeleteConfirmationModal
          open={isDeleteModalOpen}
          onClose={() => setDeleteModalOpen(false)}
          onConfirm={handleConfirmDelete}
          rowId={deleteRowId}
        />
      </div>
    );
}