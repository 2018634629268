
'use strict';

export default {
    data: [
        { id: 1, projects: 'PRJ_1, PRJ_2', objects: 'Objekt', phases: 'Bauprojekt', name: 'Angabe von neuem Datum', consumers:'Bauherr', providers:'Projektleiter', 
        parent:{ 
            name: 'Koordination Bauprojektphase',
            children_count:4, 
            children: {
                past_tasks:[{ name:'Bauprojekt schieben', order:3, related_questions:["Soll das Terminprogramm angepasst werden?"] }],
                current_task:{ name: "Angabe von neuem Datum", order:4, related_questions: ["Wird der neue Meilenstein terminlich erfasst?"] },
                future_tasks:[{ }]
            }}, 
        order:4, document:{
            url:'http://cloud.nukleus.ch/apps/files/?dir=/1000_Project%20Data/0001_ARMASUISSE/DNA-A_8506/7_Protokolle%20und%20Aktennotizen/7.7_Haustechnikingenieure&openfile=486', 
            title:'Protokoll Projektbesprechung 20190513.pdf'
        },
        doc_class:'Protokoll',  doc_date:'04.06.2023', task_date:'03.08.2023', task_loc:'Seite 2', score:97.5 },
        { id: 2, projects: 'PRJ_1, PRJ_2', objects: 'Konzept', phases: 'Projektierung', name: 'Projektierung sichergestellt Bauprojekt', consumers:'BM', providers:'Projektleiter',  
        parent:{ 
            name: 'B2 Projektierung', 
            children_count:12,
            children: {
                past_tasks:[{ name: "Projektierung sichergestellt Vorprojekt", order:4, related_questions: [] }],
                current_task:{ name: "Projektierung sichergestellt Bauprojekt", order:5, related_questions: [] },
                future_tasks:[{ name: "Projektierung optimierter Qualität", order:6, related_questions: [] }]
            }}, 
        order:5, document:{
            url:'http://cloud.nukleus.ch/apps/files/?dir=/1000_Project%20Data/0001_ARMASUISSE/DNA-A_8506/7_Protokolle%20und%20Aktennotizen/7.7_Haustechnikingenieure&openfile=473', 
            title:'200128 Protokoll Projektsitzung.pdf'
        }, 
        doc_class:'Protokoll', doc_date:'02.07.2023', task_date:'25.08.2023', task_loc:'Seite 8', score:83.2 },
        { id: 3, projects: 'PRJ_1, PRJ_2', objects: 'Konzept', phases: 'Projektierung', name: 'Projektierung optimierter Qualität', consumers:'BM', providers:'Projektleiter', 
        parent:{ 
            name: 'B2 Projektierung', 
            children_count:12,
            children: {
                past_tasks:[{ name: "Projektierung sichergestellt Bauprojekt", order:5, related_questions: [] }],
                current_task:{ name: "Projektierung optimierter Qualität", order:6, related_questions: [] },
                future_tasks:[{ name: "Projektierung optimierter Wirtschaftlichkeitsnachweis", order:7, related_questions: [] }]
            }},  
        order:6, document:{
            url:'http://cloud.nukleus.ch/apps/files/?dir=/1000_Project%20Data/0001_ARMASUISSE/DNA-A_8506/7_Protokolle%20und%20Aktennotizen/7.7_Haustechnikingenieure&scrollto=200528%20Protokoll%20Planungssitzung.pdf&openfile=466', 
            title:'200528 Protokoll Planungssitzung.pdf'
        }, 
        doc_class:'Protokoll', doc_date:'08.08.2023', task_date:'11.09.2023', task_loc:'Seite 4', score:67.3 },
    ]
}