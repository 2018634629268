import React, { createContext, useContext } from "react";
import { http } from '../https';
import config from '../config';
import dayjs from 'dayjs';
import axios from 'axios';
import predict from '../test_data/predict';
import search from '../test_data/search';
import { useAuth } from "./AuthContext";
const AppContext = createContext(null);

export const useAppContext = () => {
    return useContext( AppContext );
};

export const AppProvider = ({children}) => {

    /************************************************************************************* */

    /**
     * AUTH
     */
    const { token, userTenants, currentTenant, logout } = useAuth();

    const [userData, setUserData] = React.useState(null);
    const [userProjects, setUserProjects] = React.useState([]);
    const [userDataError, setUserDataError] = React.useState(null);
    const [isLoadingUserData , setIsLoadingUserData ] = React.useState(null);

    /** 
     * Navbar STATE
     */
    const [project, setProject] = React.useState('pr_id1');
    const [drawerOpen, setDrawerOpen] = React.useState(true);
    const [activeTab, setActiveTab] = React.useState(0);
    const [activePage, setActivePage] = React.useState(0);
    const [pageTabs, setPageTabs] = React.useState([]);

    /**
     * Task Predictions STATE
     */
    const [startDate, setStartDate] = React.useState(dayjs());
    const [endDate, setEndDate] = React.useState(dayjs());
    const [task_predictions, setTaskPredictions] = React.useState([]);
    const [tasksResultsLoading, setTasksResultsLoadingStatus] = React.useState(false);

    /**
     * Document Search STATE
     */
    const [query, setQuery] = React.useState(null);
    const [searchParams, setSearchParams] = React.useState({});
    const [doc_results, setDocResults] = React.useState([]);
    const [top_k, setTop_k] = React.useState(10);
    const [docsResultsLoading, setDocsResultsLoadingStatus] = React.useState(false);

    /**
     * Q&A LLM STATE
     */
    const [qa_docs, setQADocs] = React.useState([]);
    const [qa_history, setQAHistory] = React.useState([]);
    const [qa_results, setQAResults] = React.useState([]);
    const [qa_query_controller, setQAQueryController] = React.useState(null);
    const [qaQueryLoading, setQAQueryLoading] = React.useState(false);
    const [qaFeedbackMessage, setQAFeedbackMessage] = React.useState("");
    const [qaDeleteLoading, setQADeleteLoading] = React.useState(false);
    const [qaUploadLoading, setQAUploadLoading] = React.useState(false);
    const [qaReportCreationLoading, setQAReportCreationLoading] = React.useState(false);
    /* const [qaUploadLoading, setQAUploadLoading] = React.useState(false); */
    /*     const [qa_upload_controller, setQAUploadController] = React.useState(new AbortController()); */
    

    /**
     * ReportTemplates Management STATE
     */
    const [reportTemplates, setReportTemplates] = React.useState([]);
    const [reportTemplatesLoading, setReportTemplatesLoading] = React.useState(false);


    /**
     * ReportInstances Management STATE
     */ 
    const [reportInstances, setReportInstances] = React.useState([]);
    const [reportInstancesLoading, setReportInstancesLoading] = React.useState(false);

    /**
     * Project Management STATE
     */
    const [projects, setProjects] = React.useState([]);
    const [projectsLoading, setProjectLoading] = React.useState(false);

    /************************************************************************************* */

    /** 
     * General METHODS
     */
    const getClientId = async() => {
        return 2;
    };

    const isDatePast = (inputDate) => {
        if (!inputDate) {
            //console.log( "No date provided. Please provide a valid date in the format 'YYYY-MM-DD'.");
            return null;
        }
    
        const today = new Date(); // Get today's date
        const givenDate = new Date(inputDate); // Convert input string to Date object
    
        // Check if the given date is valid
        if (isNaN(givenDate.getTime())) {
            //console.log ("Invalid date format. Please provide a valid date in the format 'YYYY-MM-DD'.");
            return null;
        }
    
        // Reset time part for both dates to ensure an accurate date-only comparison
        today.setHours(0, 0, 0, 0);
        givenDate.setHours(0, 0, 0, 0);
    
        if (givenDate < today) {
            //console.log ( "The given date is in the past.");
            return true;
        } else if (givenDate >= today) {
            //console.log ( "The given date is in the future, or today.");
            return false;
        } else {
            //console.log ( "The given date is invalid.");
            return false;
        }
    };

    /**
     * Calculated Data
     */
    const getCalculatedData = async (project_path) => {
        try {
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `projects/calculation`, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                'Path': project_path
                }
            });

            let api_data = response.data;
            console.log('Data received:', api_data);
            
            return api_data;

        } catch (error) {
            console.log('Request failed', error.message);
            return null;
            //setTasksResultsLoadingStatus(false);
        }
    };

    /**
     * Extracted Data
     */
    const getExtractedData = async (project_path) => {
        //setTasksResultsLoadingStatus(true);

        try {
            
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `projects/extract`, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                'Path': project_path
                }
            });

            let api_data = Object.values(response.data);
            console.log('Extracted Data received:', api_data);
            let allTasks = []; // This will store all tasks across all documents
            let allSummaries = [];

            api_data.forEach(document => {
                document.extract.tasks.forEach(task => {
                    // Create a new task object with document attributes added and enforce fields
                    let taskWithDocumentInfo = {
                        text: task.text || "", // Enforce "text" field
                        assignee: task.assignee || "", // Enforce "assignee" field
                        due_date: task.due_date || "", // Enforce "due_date" field
                        document_id: document.file_id,
                        path: document.path,
                        file_name: document.file_name,
                        project: document.project,
                        tenant: document.tenant,
                        documents: [{ // 'documents' is an array that includes this document's info
                            document_id: document.file_id,
                            path: document.path,
                            file_name: document.file_name,
                            project: document.project,
                            tenant: document.tenant,
                        }], 
                        status: isDatePast(task.due_date)// calculated status
                    };
            
                    allTasks.push(taskWithDocumentInfo); // Add the enhanced task to the allTasks array

                    let summary = {
                        date: document.creation_date,
                        text: document.extract.summary
                    }

                    allSummaries.push(summary);
                });
            });

            /* setTaskPredictions(allTasks);
            setTasksResultsLoadingStatus(false); */
            return [allTasks, allSummaries];

        } catch (error) {
            console.log('Request failed', error.message);

            return [], [];
            //setTasksResultsLoadingStatus(false);
        }
    };

    /**
     * Q&A LLM METHODS
     */
    const postCreateReport = async (source, title, model, response_format, reference_documents, system_instructions, user_instructions) => {
        setQAReportCreationLoading(true);
        //setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`);
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('title', title);
        formData.append('system_instructions', system_instructions);
        formData.append('user_instructions', user_instructions);
        formData.append('model', model);
        formData.append('response_format', response_format);

        let jsonPayload = JSON.stringify(reference_documents);
        formData.append('reference_documents_info', jsonPayload);
       /*  reference_documents.forEach((file) => {
            formData.append('reference_documents_info', file);
        }); */
    
        try {
            const response = await http.post(source + 'report', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }/* ,
                  signal: qc.signal */
            });
    
            let api_data = Object.values(response.data);       
            console.log('RE Response: ', api_data);

            setQAReportCreationLoading(false);
            /* setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`); */

            return {"title": api_data[0], "creation_date": api_data[1], "result":api_data[2], "reference_documents":api_data[3]}; // Return the data so it can be used by the function caller
        } catch (thrown) {
            setQAReportCreationLoading(false);
            /* setQAUploadLoading(false); */
            if (axios.isCancel(thrown)) {
                /* setQAFeedbackMessage(`File upload cancelled.`); */
                console.log('Upload cancelled', thrown.message);
            } else {
                console.log('Upload failed', thrown.message);
                /* setQAFeedbackMessage(`File upload failed.`); */
                throw thrown; // Rethrow the error to allow the caller to catch it
            }
        }
    };

    const uploadReferenceDocuments = async (source, title, files) => {
        /* setQAUploadLoading(true);
        setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`); */
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('title', title);
        files.forEach((file) => {
            formData.append('reference_documents', file);
        });

        try {
            const response = await http.post(source + 'upload_references', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }/* ,
                  signal: qc.signal */
            });
    
            let api_data = Object.values(response.data);       
            console.log('RE Response: ', api_data);

            /* setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`); */

            return api_data[0]; // Return the data so it can be used by the function caller

        } catch (thrown) {
            /* setQAUploadLoading(false); */
            if (axios.isCancel(thrown)) {
                /* setQAFeedbackMessage(`File upload cancelled.`); */
                console.log('Upload cancelled', thrown.message);
            } else {
                console.log('Upload failed', thrown.message);
                /* setQAFeedbackMessage(`File upload failed.`); */
                throw thrown; // Rethrow the error to allow the caller to catch it
            }
        }
    };

    const clearReferenceDocuments = async (source, title) => {
        /* setQAUploadLoading(true);
        setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`); */
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('title', title);

        try {
            const response = await http.post(source + 'clear_report_files', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                  }/* ,
                  signal: qc.signal */
            });
    
            let api_data = Object.values(response.data);       
            console.log('RE Response: ', api_data);

            /* setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`); */

            return api_data; // Return the data so it can be used by the function caller

        } catch (thrown) {
            /* setQAUploadLoading(false); */
            if (axios.isCancel(thrown)) {
                /* setQAFeedbackMessage(`File upload cancelled.`); */
                console.log('Upload cancelled', thrown.message);
            } else {
                console.log('Upload failed', thrown.message);
                /* setQAFeedbackMessage(`File upload failed.`); */
                throw thrown; // Rethrow the error to allow the caller to catch it
            }
        }
    };

    const uploadQAFileToCache = async (source, file) => {
        setQAUploadLoading(true);
        setQAFeedbackMessage(`Uploading File ${file.name}, with size: ${file.size} to Cache.`);
        /* const qc = new AbortController()
        setQAUploadController(qc); */
        const formData = new FormData();
        formData.append('file', file);
        await http.post(source + `upload`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }/* ,
            signal: qc.signal */
        }).then(
            function (res) {
                let api_data = Object.values(res.data);  
                api_data = [api_data[1]]      
                console.log('QA Response: ', api_data);
                setQAUploadLoading(false);
                setQAFeedbackMessage(`File ${file.name} successfully uploaded.`);
            }
        ).catch(
            function(thrown) {
                setQAUploadLoading(false);
                if (axios.isCancel(thrown)) {
                    console.log('Upload cancelled', thrown.message);
                    setQAFeedbackMessage(`File upload cancelled.`);
                } else {
                    console.log('Upload failed', thrown.message);
                    setQAFeedbackMessage(`File upload failed.`);
                }
            }
        )
        
    };

    const getQASearchResults = async (source, query, clear_history=false) => {
        setQAResults([]);
        setQAQueryLoading(true);
        const qc = new AbortController()
        setQAQueryController(qc);
        setQAFeedbackMessage(`Query "${query.slice(0,10)}..." is being processed.`);
        await http.post(source + `search-answer?query=` + query, {}, { 
            signal: qc.signal
        }).then(
            function(response) {
                let api_data = Object.values(response.data);  
                api_data = [api_data[1]]       
                console.log('QA Response, unfiltered: ', api_data);
                if( typeof api_data[0] === 'string' || api_data[0] instanceof String ){
                    setQAResults([]);
                } else {
                    setQAResults(api_data);
                    
                    let history = []
                    if(!clear_history) {
                        history = [{"question":query, "answer":api_data[0]}].concat(qa_history)
                    } else {
                        history = [{"question":query, "answer":api_data[0]}]
                    }

                    setQAHistory(history)
                }
                setQAQueryLoading(false); 
            }
        ).catch(function (thrown) {
            setQAQueryLoading(false);
            if (axios.isCancel(thrown)) {
                console.log('Request cancelled', thrown.message);
                setQAFeedbackMessage(`Query cancelled.`);
            } else {
                console.log('Request failed', thrown.message);
                setQAFeedbackMessage(`Query failed.`);
            }
          });
    };

    // TODO: add error Catching
    const clearQACacheData = async (source) => {
        setQADeleteLoading(true);
        setQAFeedbackMessage("Removing Files from Cache.");
        const res = await http.post(source + `clear`);
        let api_data = Object.values(res.data);  
        api_data = [api_data[1]]       
        console.log('QA Response: ', api_data);
        setQADeleteLoading(false);
        setQAFeedbackMessage("Cache cleared.");
    };

    const getQADocs = async (source) => {
        setQADocs([]);
        //setQAFeedbackMessage(`Retrieving File List from Cache.`);
        await http.get(source + `files`).then(
            function(response) {
                console.log('Response: ', response.data.files);
                setQADocs(response.data.files);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setQAFeedbackMessage('Request for File List failed.');
            }
        )
    };

    const abortQAQuery = () => {
        qa_query_controller.abort();
        setQAQueryLoading(false); 
    };

    /* const abortQAUpload = () => {
        qa_upload_controller.abort();
        setQAUploadLoading(false); 
    }; */

    const removeItemFromQAResults = (index) => {
        console.log(`Removing Item ${index} from results list.`)
        let history =  qa_history.slice();
        history.splice(index, 1);
        setQAHistory(history)
    };

    const fetchQAData = async (source, newQuery, clear_history) => {
        await getQASearchResults(source, newQuery, clear_history);
    };

    const fetchReportData = async (title, model, response_format, reference_documents, system_instructions, user_instructions) => {
        console.log('Posting Report: ', title);
        try {
            // Directly await the promise returned by postCreateReport
            const response = await postCreateReport(config.envs[config.active_env].qa_api_url, title, model, response_format, reference_documents, system_instructions, user_instructions);
            console.log('RE data received:', response);
            // Return the response so it can be used by the caller of fetchReportData
            return response;
        } catch (error) {
            // Log and re-throw the error to allow the caller to handle it
            console.error(error);
            throw error;
        }
    };

    const uploadReportDocs = async (title, files) => {
        console.log('Posting References for Report: ', title);
        try {
            // Directly await the promise returned by postCreateReport
            const response = await uploadReferenceDocuments(config.envs[config.active_env].qa_api_url, title, files);
            console.log('RE data received:', response);
            // Return the response so it can be used by the caller of fetchReportData
            return response;
        } catch (error) {
            // Log and re-throw the error to allow the caller to handle it
            console.error(error);
            throw error;
        }
    };

    const clearReportDocs = async (title) => {
        console.log('Clearing References for Report: ', title);
        try {
            // Directly await the promise returned by postCreateReport
            const response = await clearReferenceDocuments(config.envs[config.active_env].qa_api_url, title);
            console.log('RE data received:', response);
            // Return the response so it can be used by the caller of fetchReportData
            return response;
        } catch (error) {
            // Log and re-throw the error to allow the caller to handle it
            console.error(error);
            throw error;
        }
    };

    const clearQAData = async (source) => {
        await clearQACacheData(source);
        await getQADocs(config.envs[config.active_env].qa_api_url);
    };

    const uploadQAData = async (source, file) => {
        await uploadQAFileToCache(source, file);
        await getQADocs(config.envs[config.active_env].qa_api_url);
    };

    const qadocList = async () => {
        console.log('Query Doc List'); 
        await getQADocs(config.envs[config.active_env].qa_api_url);
    }

    const qaSearch = (query, clear_history=false) => {
        console.log('Posting Query: ', query);
        if (query!== null) { 
            fetchQAData(config.envs[config.active_env].qa_api_url, query, clear_history)
            .then(
                console.log('QA data received')
                )
            .catch(console.error);
        }
    };

    const qaClearDocs = () => {
        console.log('Clearing Docs in Cache.');
        clearQAData(config.envs[config.active_env].qa_api_url)
            .then(
                console.log('QA data cleared.')
                )
            .catch(console.error);
    };

    const qaUploadDocument = (file) => {
        console.log('Uploading Document to Cache.');
        if (file!== null) { 
            uploadQAData(config.envs[config.active_env].qa_api_url, file)
            .then(
                console.log('QA data uploaded.')
                )
            .catch(console.error);
        }
    };

    /**
     * Project Management METHODS
     */
    const createOrUpdateProject = async(project_data) => {
        setProjectLoading(true);
        await http.post(config.envs[config.active_env].domain + config.api_path + `projects/update`, project_data, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                /* 'OCS-APIRequest': 'true' */
              }/* ,
              signal: qc.signal */
        }).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Project created/updated:', api_data);
                setProjectLoading(false);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setProjectLoading(false);
            }
        ) 
    };

    const deleteProject = async (project_path) => {
        /* const project_name = project_path.split('/')[2];
        console.log('project_name', project_name); */
        setProjectLoading(true);
        await http.delete(config.envs[config.active_env].domain + config.api_path + `projects/delete`/* ?project_name=` + project_name */, {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': currentTenant,
                /* 'OCS-APIRequest': 'true', */
                'Path': project_path,
              }/* ,
              signal: qc.signal */
        }).then(
            async function(response) {
                let api_data = Object.values(response.data);
                console.log('Project deleted:', api_data);
                setProjectLoading(false);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setProjectLoading(false);
            }
        ) 
    };   
    
    const getUserData = async (tenant) => {
        // Initial state reset can be done synchronously; they don't return promises
        setUserData(null);
        setUserProjects([]);
        setIsLoadingUserData(true);
    
        try {
            const response = await http.get(config.envs[config.active_env].domain + config.api_path + `projects/list-projects`, {
                headers: {
                    // Include the Authorization header with your token
                    Authorization: `Bearer ${token.accessToken}`
                }
            });
            let api_data = Object.values(response.data);
            console.log('Project list for user loaded:', api_data);
    
            const user_details = {
                "user": api_data[0],
                "email": api_data[1]
            };
            setUserData(user_details);
            
            const allPaths = [...new Set(api_data[2].map(({ path }) => path))];
            let paths = [];
            if (tenant) {
                paths = allPaths.filter(path => path.includes(tenant));
            } else {
                paths = allPaths;
            }
            setUserProjects(paths);
            setIsLoadingUserData(false);
            
            return paths; 
        } catch (error) {
            console.log('Request failed', error.message);
            setUserData(null);
            setUserProjects([]);
            setIsLoadingUserData(false);
            logout();
            // Rethrow or handle the error as needed
            //throw error; // If you want the caller to handle the error
        }
    };

    const getProjectDetailList = async(tenant, projects) => {
        await setProjects([]);
        await setProjectLoading(true);


        console.log('Tenant: ', tenant);
        console.log('Projects: ', projects);

/*         console.log('Function Projects: ', projects);
        console.log('State Projects: ', userProjects); */

        //const user_projects = projects;// projects.length > 0 ? projects : userProjects;
       /*  let client = getClientId(); */

        // Add abort Controller
        // Add Feedback Message
        //?title=json-server&author=typicode
        await http.get(config.envs[config.active_env].domain + config.api_path + `projects/details`,
        
        {
            headers: {
                // Include the Authorization header with your token
                Authorization: `Bearer ${token.accessToken}`,
                'Tenant': tenant,
                'Paths':projects.join(',')
              }/* ,
              signal: qc.signal */
        }
        
        ).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Projects loaded:', api_data);
                setProjects(api_data);
                setProjectLoading(false);
                return api_data;
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setProjectLoading(false);
            }
        ) 
    }

    /**
     * ReportTemplates Management METHODS
     */
    // TODO: Check if instances use template_id, In use Templates cannot be deleted.
    const createReportInstance = async (template_data) => {
        await http.post(config.mock_server_api_url + `report_instances`, template_data).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Instance created:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        ) 
    };

    const getReportTemplatesList = async() => {
        setReportTemplates([]);
        setReportTemplatesLoading(true);

        let client = getClientId();

        // Add abort Controller
        // Add Feedback Message
        //?title=json-server&author=typicode
        await http.get(config.mock_server_api_url + `report_templates?` + client).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Templates loaded:', api_data);
                setReportTemplates(api_data);
                setReportTemplatesLoading(false);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setReportTemplatesLoading(false);
            }
        ) 
    }

    const createReportTemplate = async(template_data) => {
        await http.post(config.mock_server_api_url + `report_templates`, template_data).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Template created:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        ) 
    };

    const updateReportTemplate = async(template_id, template_data) => {
        await http.put(config.mock_server_api_url + `report_templates/` + template_id, template_data).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Template updated:', api_data);   
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        ) 
    };

    // TODO: Check if instances use template_id, In use Templates cannot be deleted.
    const removeReportTemplate = async (template_id) => {
        await http.delete(config.mock_server_api_url + `report_templates/${template_id}`).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Template deleted:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        );
    };


    /**
     * ReportInstances Management METHODS
     */
    const getReportInstancesList = async() => {
        setReportInstances([]);
        setReportInstancesLoading(true);

        let client = getClientId();

        // Add abort Controller
        // Add Feedback Message
        //?title=json-server&author=typicode
        await http.get(config.mock_server_api_url + `report_instances?` + client).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Instances loaded:', api_data);
                setReportInstances(api_data);
                setReportInstancesLoading(false);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
                setReportInstancesLoading(false);
            }
        ) 
    }

    // TODO: Check if instances use template_id, In use Templates cannot be deleted.
    const removeReportInstance = async (instance_id) => {
        await http.delete(config.mock_server_api_url + `report_instances/${instance_id}`).then(
            function(response) {
                let api_data = Object.values(response.data);
                console.log('Report Instance deleted:', api_data);
            }
        ).catch(
            function(error) {
                console.log('Request failed', error.message);
            }
        );
    };
    
    /**
     * Document Search STATE
     */  
    const getSearchResultsInDocs = async (source, top_k, project, query, directory="/") => {
        setDocResults([]);
        setDocsResultsLoadingStatus(true);
        const project_list = project == 'all' ? userProjects.join(',') : project; 
        if(config.use_mock_data){
            setDocResults(search[activeTab].slice(0,parseInt(top_k)));
            setDocsResultsLoadingStatus(false);
        } else {
            
            const res = await http.post(source + `search-document`, {
                
                    /* "client": userTenants[0], */ //TODO: check if needed, probably redundant 
                    "directory": `${directory}`,
                    "top_k":top_k,
                    "phrase":query
            
            }, {
                headers: {
                    // Include the Authorization header with your token
                    Authorization: `Bearer ${token.accessToken}`,
                    'Tenant': currentTenant,
                    /* 'OCS-APIRequest': 'true', */
                    'Paths': project_list,
                  }/* ,
                  signal: qc.signal */
            });

            //console.log("RES:", res);
            const api_data = Object.values(res.data);         
            console.log('ES Response, unfiltered: ', api_data[0]);
            
            /* let filtered_api_data = [];

            for(let i = 0; i < api_data[0].length; i++) {
                const pathname = api_data[0][i].source.path;
                let path_from_tab = '/' + pageTabs[activeTab] + '/'; 
                path_from_tab = path_from_tab.slice(0,6);
                if(filter != 'all') {
                    if (pathname.toLowerCase().includes(path_from_tab.toLowerCase()) && pathname.toLowerCase().includes(filter.toLowerCase())) {
                        filtered_api_data.push(api_data[0][i])
                    }
                } else {
                    if (pathname.toLowerCase().includes(path_from_tab.toLowerCase())) {
                        filtered_api_data.push(api_data[0][i])
                    }
                }
            } */

            // TODO: Top_k should come from backend, this should be removed.
            //const top_k_filtered_api_data = filtered_api_data.slice(0,parseInt(top_k));

            setDocResults(api_data[0]);
            setDocsResultsLoadingStatus(false);
        }    
    }

    /* const fetchESData = async (source, result_count, project, query, directory) => {
        await getSearchResultsInDocs(filter, query, result_count, source, project, directory);
    }; */
    
    const documentSearch = (project, query, directory) => {
        console.log('Posting Project:', project, ' Query: ', query);
        
        if (query!== null && project!== null) {

            getSearchResultsInDocs(config.envs[config.active_env].domain + config.api_path, top_k, project, query, directory)
                .then(
                    console.log('ES data received')
                    )
                .catch(console.error); 
        }
    };

    /************************************************************************************* */

    /**
     * DATA
     */

    const data = {
        userData, isLoadingUserData , setIsLoadingUserData, userDataError, getUserData, 
        userProjects, userTenants, 

        top_k, setTop_k, project, setProject, query, setQuery,
        drawerOpen, setDrawerOpen, activeTab, setActiveTab, activePage, setActivePage, pageTabs, setPageTabs,

        documentSearch, doc_results, setDocResults, docsResultsLoading, setDocsResultsLoadingStatus,
        startDate, setStartDate, endDate, setEndDate, searchParams, setSearchParams,
       
        qa_results, setQAResults, qaSearch, qaClearDocs, qaUploadDocument, qa_history, setQAHistory, 
        qadocList, qa_docs, abortQAQuery, removeItemFromQAResults, qaFeedbackMessage, setQAFeedbackMessage,
        qaQueryLoading, setQAQueryLoading, qaDeleteLoading, setQADeleteLoading, qaUploadLoading, setQAUploadLoading, 
       
        task_predictions, getExtractedData, tasksResultsLoading, setTasksResultsLoadingStatus, getCalculatedData, 

        projects, setProjects, /* createProject, updateProject, removeProject, */ projectsLoading, 
        deleteProject, createOrUpdateProject, getProjectDetailList, 

        reportTemplates, getReportTemplatesList, createReportTemplate, createReportInstance, updateReportTemplate, removeReportTemplate, reportTemplatesLoading,
        reportInstances, getReportInstancesList, reportInstancesLoading, removeReportInstance,

        fetchReportData, uploadReportDocs, clearReportDocs, qaReportCreationLoading, setQAReportCreationLoading 
    }

    return(
        <AppContext.Provider value={data}>
            {children}
        </AppContext.Provider>
    )
};