import React, {useEffect} from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useAppContext } from '../contexts/AppContext';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import NumberInputBasic from './NumberInputBasic';

import { useSearchParams } from "react-router-dom";
import BasicTabs from './NavigationTabs';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '80ch',
    },
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  padding:'0px !important',
  margin:'0px !important',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 70,
  },
}));

const topicMenuItems =  [
  "Bauleitung",
  "Baumanagement Immobilien",
  "Bautechnik",
  "Beschaffung",
  "Boden Altlasten",
  "Brandschutz",
  "Denkmalschutz",
  "Elektro, MSRL, EMP-Schutz",
  "Energie",
  "Erdbebensicherheit",
  "Finanzen",
  "Gebäudeautomatisation",
  "Gebäudeschadstoffe",
  "Gebäudetechnik (HLKKS)",
  "Hermes",
  "Immobilien Managment",
  "Lärm",
  "Naturschutz",
  "Normen",
  "Photovoltaikanlagen",
  "Projektabschluss",
  "Projektdokumentation",
  "Rechnung",
  "Recht",
  "Safety und Security",
  "Sanitäranlagen",
  "Schutzbau",
  "Störfall, WLGF",
  "Stromversorgung",
  "Telematik",
  "Umweltmanagement und Nachhaltigkeit",
  "Wasser"
];

export default function PrimarySearchAppBar() {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [project, setProject] = React.useState('all');

  let [searchParams, setSearchParams] = useSearchParams();

  const { setQuery, /* setProject, set_res_count, results_count,  */activeTab, documentSearch, userProjects } = useAppContext();

  const handleProjectSelection = (event) => {
    console.log('Setting project: ', event.target.value);
    setProject(event.target.value);
  };

  const runSearchQuery = () => {
    if (searchQuery) {
      console.log('Requesting matches for Filter: ', project, ' with Query: ', searchQuery )
      //setProject(project);
      setQuery(searchQuery);
      documentSearch(project, searchQuery, "/");
      //setSearchQuery('');

    }
  };

  //If QueryParam query is present, set searchQuery, execute runSearchQuery
  const loadQueryFromURLParams = () => {
    if(searchParams) {
      const newQuery = searchParams.get('query');
      if (newQuery) {
        console.log('newQuery: ', newQuery);
        setSearchQuery(newQuery);
        /*  */
        setQuery(newQuery);
        /* console.log('searchQuery: ', searchQuery); */
        /* setSearchQuery(newQuery);
        setProject(project);
        setQuery(searchQuery); */
        documentSearch(project, newQuery, "/");
        searchParams.delete('query');
        if(searchParams.has('tab_id')) {
          searchParams.delete('tab_id');
        }
        setSearchParams(searchParams);
      }

    }
  };

  useEffect(() => {
    console.log("SearchAppbar: ", searchParams);
    loadQueryFromURLParams();
    }, 
    []
  );

  return (
    <Box id='root_box_appbar' sx={{ flexGrow: 1 }}>    
      <Box id='appbar_box'>
        <BasicTabs/>
        <AppBar position="static" sx={{backgroundColor: '#ccc'}}>
            <StyledToolbar id='styled_toolbar'>
            
            <Grid id='main_toolbar_grid' container direction={ 'column' } sx={{ flexGrow: 1, alignSelf: 'flex-end'}}>
              <Box id='appbar_top_row' sx={{
                width:'100%',
                padding:'0px !important',
                margin:'0px !important',
                padding:'0',
                flexGrow: 1, alignSelf: 'flex-end',
                }}>
                  
                <Grid id='appbar_top_grid' container sx={{ padding:'0px !important', width:'100%', flexGrow: 1, alignSelf: 'flex-end'}}> 
                 {/*  <Grid id='appbar_top_griditem_left' item xs={1}></Grid> */}
                  <Grid id='appbar_top_griditem_center' container item xs={12} sx={{padding:1}}>

                    <Grid id='appbar_top_gridsubitem_main' item xs={10} sx={{ paddingLeft:'0px !important', flexGrow: 1, alignSelf: 'flex-end'}}>
                      <Search sx={{ paddingLeft:'0px !important', marginLeft:'0px !important', color:'black', backgroundColor:'white'  }}>
                        <SearchIconWrapper>
                          <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase sx={{flexGrow: 1, maxWidth:'none', width:'100% !important', margin:'0 !impoertant', padding:'0 !impoertant'}} 
                          value={searchQuery}
                          onInput={(e) => setSearchQuery(e.target.value)}
                          placeholder="Suchen nach..."
                        />
                      </Search>
                    </Grid>
                  
                    <Grid container item xs={2} sx={{ flexGrow: 1, alignSelf: 'flex-end'}}>
                      <Button variant="contained" 
                        onClick={runSearchQuery}
                        sx={{ 
                          flexGrow: 1, 
                          backgroundColor:'white', 
                          color:'black', 
                          border:'1px solid black',
                          '&:hover': {
                            background: "black",
                            color:'white'
                        }, }}>
                          Suchen
                      </Button>
                    </Grid>

                  </Grid>
                  {/* <Grid id='appbar_top_griditem_right' item xs={1} ></Grid> */}
                </Grid>

              </Box>

              <Box id='appbar_bottom_row' sx={{
                width:'100%', paddingTop:'0px', paddingBottom:'0px', margin:'auto',
                flexGrow: 1, alignSelf: 'flex-end',
                }}>

                <Grid id='appbar_bottom_grid' container sx={{ paddingLeft: '0px', flexGrow: 1, alignSelf: 'flex-end' }}>
                  <Grid id='appbar_bottom_griditem_center' item xs={12} sx={{ padding: 1, paddingTop: 0, flexGrow: 1, alignSelf: 'flex-end' }}>
                    <Grid container direction='row' sx={{ flexGrow: 1, justifyContent: 'flex-start' }}>
                      <Grid item sx={{ paddingRight: '15px' }}> {/* This item takes space dynamically */}
                        <InputLabel id="project-label" sx={{ textAlign: 'left', paddingBottom: '2px', fontSize: '0.7rem', lineHeight: '1.4375em' }}>
                          {activeTab === 0 ? "Projekte" : "Themen"}
                        </InputLabel>
                        <Select
                          labelId="project-label"
                          id="project"
                          value={project}
                          onChange={handleProjectSelection}
                          sx={{ backgroundColor: 'white', height: '40px', width: 'auto' }} // Auto width for natural space usage
                        >
                          {activeTab === 0 ? (
                            userProjects.map((projectItem) => (
                              <MenuItem key={projectItem} value={projectItem}>{projectItem}</MenuItem>
                            ))
                          ) : (
                            topicMenuItems.map((item, index) => (
                              <MenuItem key={index} value={item}>{item}</MenuItem>
                            ))
                          )}
                          <MenuItem value={'all'}>{activeTab === 0 ? "Alle Projekte" : "Alle Themen"}</MenuItem>
                        </Select>
                      </Grid>

                      <Grid item xs={2} sx={{ flexGrow: 0 }}> {/* This item has a fixed width */}
                        <InputLabel id="res_count_label" sx={{ textAlign: 'left', paddingBottom: '2px', fontSize: '0.7rem', lineHeight: '1.4375em' }}>
                          Max. Ergebnisanzahl
                        </InputLabel>
                        <NumberInputBasic />
                      </Grid>
    
                    </Grid>
                  </Grid>
                </Grid>

              </Box>
            </Grid>
          </StyledToolbar>
        </AppBar>
      </Box>
    </Box>
  );
}