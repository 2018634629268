// Reports
import React, { useState } from 'react';
import ReportTemplatesGrid from '../components/ReportTemplatesGrid';
import ReportInstancesGrid from '../components/ReportInstancesGrid';
import { styled } from '@mui/material/styles';

import { useAppContext } from '../contexts/AppContext';
/* import AppBar from '../components/HomeAppbar'; */
import LinearProgressIndeterminate from '../components/LinearProgressIndeterminate';
import DrawerHeader from '../components/NavigationHeader';
import BasicTabs from '../components/NavigationTabs';

import config from '../config';
const drawerWidth = config.ui_settings.drawer_width;//240;

export default function ReportPage() {
    const { drawerOpen, activeTab, pageTabs } = useAppContext();  

    const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
      ({ theme, drawerOpen }) => ({
        flexGrow: 1,
        padding: theme.spacing(0),
        marginLeft: `calc(${theme.spacing(8)} + 1px)`,
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(drawerOpen && {
          transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
          }),
          marginLeft: `calc(${drawerWidth}px)`,
        }),
      }),
    );


    return (

      <Main drawerOpen={drawerOpen}>
        <DrawerHeader />
        <BasicTabs/>
        {pageTabs[activeTab] === 'Vorlagen' ? <ReportTemplatesGrid /> : <ReportInstancesGrid />}
      </Main>
        
    );
}