'use strict';

export default {
    0: [
        { source: {path:'http://cloud.nukleus.ch/apps/files/?dir=/1000_Project%20Data/Thema%20was%20mich%20interesssierzt&openfile=64694'},file_name: 'K1P53_F02d_Übergabe-_Übernahmeprotokoll.PDF', score:1.798, matches:[
            {location:[1],match:'Der beauftragte Planer / <em>Architekt</em>', sentiment:4.5},
            {location:[1],match:'Der Beauftragte (Planer/<em>Architekt</em>)', sentiment:4.5},
         ], doc_class:'Vorlage',  parse_date:'02.04.2023' 
        },
    ],
    1: [
        { source: {path:'http://cloud.nukleus.ch/apps/files/?dir=/1000_Project%20Data/test_projects/Ar&openfile=62200'},file_name: '10040 PRO KOORDINATION 180614.PDF', score:1.2198, matches:[
            {location:[1],match:'Käser (CK) Christian Halter Hunziker <em>Architekten Architekt<\em> X X', sentiment:4.6},
            {location:[4],match:'Abgabe KV Haustechnik 07.09.18; Abgabe KV Gesamt durch <em>Architekt<\em> Mitte Sept. 18', sentiment:4},
            {location:[7],match:'Abgabe KV Haustechnik 04.09.18; Abgabe KV Gesamt durch <em>Architekt<\em> 07.09.18', sentiment:3.75},
         ], doc_class:'Protokoll',  parse_date:'14.06.2018' 
        },
        
        { source: {path:'http://cloud.nukleus.ch/apps/files/?dir=/1000_Project%20Data/test_projects/Ar&openfile=62203'}, file_name: '10040 PRO KOORDINATION 180906.PDF', score:0.9678, matches:[
            {location:[1],match:'Käser (CK) Christian Halter Hunziker <em>Architekten Architekt<\em> X X', sentiment:4.9},
            {location:[4],match:'Abgabe KV Haustechnik 07.09.18; Abgabe KV Gesamt durch <em>Architekt<\em> Mitte Sept. 18', sentiment:2.85},
         ], doc_class:'Protokoll',  parse_date:'06.09.2018' 
        },

        { source: {path:'http://cloud.nukleus.ch/apps/files/?dir=/1000_Project%20Data/test_projects/Ba&openfile=62342'}, file_name: '14.02.11 PRKS BASSERSDORF 14-07.PDF', score:4.7845, matches:[
            {location:[1],match:'Herr Roger Lienert Ghisleni Planen Bauen GmbH, PL <em>Architekt</em> RL roger.lienert@ghisleni.ch', sentiment:2.9},    
            {location:[2],match:'Aussparungen in der Druckverteilplatte Lager mit CB/SSP und <em>Architekt</em> GAB im Bereich Kühlzellen ist zu klären (Schwellenloser Übergang) – vorgängig mit VT klären (Punkt 3.1.2)', sentiment:0.67},  
         ], doc_class:'Protokoll',  parse_date:'11.02.2014' 
        },
    ]
}