import React from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import InputBase from '@mui/material/InputBase';
import SearchIcon from '@mui/icons-material/Search';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useAppContext } from '../contexts/AppContext';
import Popover from '@mui/material/Popover';
import { 
  ListItemButton, 
  ListItemIcon,
  ListItemText,
  List }from '@mui/material';
  import DescriptionIcon from '@mui/icons-material/Description';
import BasicTabs from './NavigationTabs';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    /* [theme.breakpoints.up('md')]: {
      width: '80ch',
    }, */
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  alignItems: 'flex-start',
  padding:'0px !important',
  margin:'0px !important',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(2),
  // Override media queries injected by theme.mixins.toolbar
  '@media all': {
    minHeight: 70,
  },
}));

export default function PrimarySearchAppBar() {
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filter, setProjectFilter] = React.useState('all');
  const { qa_docs, setQuery, qaSearch, qaClearDocs, qaUploadDocument, qaQueryLoading, abortQAQuery, qaUploadLoading, qa_history, setQAHistory } = useAppContext();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

/*   function textifyQA(e, i) {
    return "Frage " + i + ": " + e.question + ", Antwort " + i + ": " + e.answer.response;
  } */

 /*  const summarizeScenario = () => {
    // Format qa_history as string of Q&A, Q#1: lorem ipsum?, A#1: lorem ipsum. Q#2: lorem ipsum?, A#2: lorem ipsum. 
    let qa_history_formatted = qa_history.map((e, i) => (textifyQA(e,i)));
    qa_history_formatted = qa_history_formatted.join();
    runScenarioSummary(`Welche Themen werden im diesen Szenario angesprochen?: ${{qa_history_formatted}}`)
  }

  const runScenarioSummary = (query) => {
      console.log('Requesting for Scenario Summary with Query: ', query )
      setQuery(query);
      qaSearch(query, true);
  }; */

  const runQASearchQuery = () => {
    if (searchQuery) {
      console.log('Requesting matches for Filter: ', filter, ' with Query: ', searchQuery )
      setQuery(searchQuery);
      qaSearch(searchQuery);
    }
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    console.log("Uploading file from event", file);
    qaUploadDocument(file);
  };

  const DocList = () => {
    if(Object.keys(qa_docs).length > 0) {
        return(
          <List component="div" disablePadding>
            {qa_docs.map((result, index) => (
              <ListItemButton key={index} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText>
                  {result}
                </ListItemText>
              </ListItemButton>
            ))}
          </List>  
        )          
    } else {
      return(<Typography sx={{ p: 2 }}>Keine Dateien im Zwischenspeicher.</Typography>)
    }
};

  return (
    <Box id='root_box_appbar' sx={{ flexGrow: 1 }}>    
      <Box id='appbar_box'>
        <BasicTabs/>
        <AppBar position="static" sx={{backgroundColor: '#ccc'}}>
            <StyledToolbar id='styled_toolbar'>
            
            <Grid id='main_toolbar_grid' container direction={ 'column' } sx={{ flexGrow: 1, alignSelf: 'flex-end'}}>

              <Box id='appbar_top_row' sx={{
                width:'100%',
                padding:'0px !important',
                margin:'0px !important',
                padding:'0',
                flexGrow: 1, alignSelf: 'flex-end',
                }}>

                <Grid id='appbar_bottom_grid' container sx={{padding:'0px !important', width:'100%', flexGrow: 1, alignSelf: 'flex-end'}}>

                  <Grid id='appbar_bottom_griditem_center' item xs={10} sx={{ padding:1, paddingLeft:'0px !important', flexGrow: 1, alignSelf: 'flex-end'}}>

                    <Grid container direction='row' item xs={12} sx={{ paddingLeft:'0px !important', flexGrow: 1, alignSelf: 'flex-end'}}>
                      
                      <Grid container direction='column' item xs={2} sx={{ paddingLeft:'0px !important', flexGrow: 1, alignSelf: 'flex-start'}}>
                          <input
                            accept="application/pdf"
                            style={{ display: 'none' }}
                            id="raised-button-file"
                            multiple={false}
                            type="file"
                            /* onChange={!qaUploadLoading ? handleFileUpload : null } */
                            /* onClick={qaUploadLoading ? abortQAUpload : null } */
                            onChange={handleFileUpload} 
                          />
                          <label htmlFor="raised-button-file">
                            <Button sx={{ margin:'0px !important'}}  
                              variant="contained"
                              component="span"
                              disabled={qaUploadLoading}
                            >
                              {'Datei Hochladen' } 
                            </Button>
                          </label>
                      </Grid>

                      <Grid container direction='column' item xs={3} sx={{ paddingLeft:'0px !important',  flexGrow: 1, alignSelf: 'flex-start'}}>
                        <Button sx={{ margin:'0px !important'}}  
                          variant="contained"
                          component="label"
                          onClick={qaClearDocs} 
                        >
                          Zwischenlagerung Leeren
                        </Button>
                      </Grid>

                      <Grid container direction='column' item xs={4} sx={{ paddingLeft:'0px !important', marginLeft:4, flexGrow: 1, alignSelf: 'flex-start'}}>
                        <Button aria-describedby={id} variant="contained" onClick={handleClick}>
                          Dateien im Zwischenspeicher anschauen
                        </Button>
                        <Popover
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          id={id}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                        >
                          <DocList/>
                        </Popover>
                      </Grid>

                    </Grid>
                    {/* <Grid item xs={2}></Grid> */}

                  </Grid>
                  
                </Grid>
                  
                <Grid id='appbar_top_grid' container sx={{padding:'0px !important', width:'100%', flexGrow: 1, alignSelf: 'flex-end'}}> 
               
                  <Grid id='appbar_top_griditem_center' container item xs={12} sx={{padding:1}}>

                    <Grid id='appbar_top_gridsubitem_main' item xs={10} sx={{ paddingLeft:'0px !important', flexGrow: 1, alignSelf: 'flex-end'}}>
                      
                      <Search sx={{ paddingLeft:'0px !important', marginLeft:'0px !important', color:'black', backgroundColor:'white'  }}>
                        <SearchIconWrapper>
                          <SearchIcon/>
                        </SearchIconWrapper>
                        <StyledInputBase sx={{flexGrow: 1, maxWidth:'none', width:'100% !important', margin:'0 !impoertant', padding:'0 !impoertant'}} 
                          value={searchQuery}
                          onInput={(e) => setSearchQuery(e.target.value)}
                          placeholder="Fragen stellen..."
                        />
                      </Search>
                    </Grid>
                    
                    <Grid id='appbar_top_gridsubitem_action' container item xs={2} sx={{ flexGrow: 1, alignSelf: 'flex-end'}}>
                      <Button variant="contained" 
                        onClick={qaQueryLoading ? abortQAQuery : runQASearchQuery } 
                        /* disabled={qaResultsLoading} */
                        sx={{ 
                          flexGrow: 1, 
                          backgroundColor:'white', 
                          color:'black', 
                          border:'1px solid black',
                          '&:hover': {
                            background: "black",
                            color:'white'
                        }, }}>
                          {qaQueryLoading ? 'Abbrechen' : 'Fragen' } 
                      </Button>
                      
                    </Grid>

                    {/* <Grid id='appbar_top_gridsubitem_action' container item xs={2} sx={{ flexGrow: 1, alignSelf: 'flex-end'}}>
                      <Button variant="contained" 
                        onClick={qaQueryLoading ? abortQAQuery : summarizeScenario } 
                
                        sx={{ 
                          flexGrow: 1, 
                          backgroundColor:'white', 
                          color:'black', 
                          border:'1px solid black',
                          '&:hover': {
                            background: "black",
                            color:'white'
                        }, }}>
                          {qaQueryLoading ? 'Abbrechen' : 'Szenario Zusammenfassen' } 
                      </Button>
                      
                    </Grid> */}

                  </Grid>
                </Grid>
              </Box>

            </Grid>
          </StyledToolbar>
        </AppBar>
      </Box>
    </Box>
  );
}