import React, { useState } from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Typography
} from '@mui/material';

const RecurringEventControls = () => {
  const [recurrence, setRecurrence] = useState({
    frequency: 'weekly',
    days: [],
    interval: 1
  });

  const handleFrequencyChange = (event) => {
    setRecurrence({ ...recurrence, frequency: event.target.value });
  };

  const handleDayChange = (event) => {
    setRecurrence({
      ...recurrence,
      days: event.target.checked
        ? [...recurrence.days, event.target.value]
        : recurrence.days.filter(day => day !== event.target.value)
    });
  };

  const handleIntervalChange = (event) => {
    setRecurrence({ ...recurrence, interval: event.target.value });
  };

  return (
    <Box sx={{ width: '100%', bgcolor: 'background.paper', p: 2 }}>
      <Typography variant="h6" align="left">Wiederholung</Typography>

      {/* Frequency Selector */}
      <FormControl fullWidth margin="normal">
        <InputLabel id="frequency-label">Frequency</InputLabel>
        <Select
          labelId="frequency-label"
          id="frequency"
          value={recurrence.frequency}
          label="Frequency"
          onChange={handleFrequencyChange}
        >
          <MenuItem value="daily">Daily</MenuItem>
          <MenuItem value="weekly">Weekly</MenuItem>
          <MenuItem value="monthly">Monthly</MenuItem>
          <MenuItem value="yearly">Yearly</MenuItem>
        </Select>
      </FormControl>

      {/* Interval Input */}
      <TextField
        label="Interval"
        type="number"
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        value={recurrence.interval}
        onChange={handleIntervalChange}
        margin="normal"
        fullWidth
      />

      {/* Days of the Week Selector */}
      {recurrence.frequency === 'weekly' && (
        <FormGroup>
          {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={recurrence.days.includes(day)}
                  onChange={handleDayChange}
                  value={day}
                />
              }
              label={day}
              key={day}
            />
          ))}
        </FormGroup>
      )}
    </Box>
  );
};

export default RecurringEventControls;
