import React from 'react';
import './App.css';
import {Route, Routes, Navigate } from "react-router-dom";
import LoginPage from './pages/LoginPage';
import Search from './pages/DocumentSearch';
import QA from './pages/QA';
import ProjectsPage from './pages/Projects';
import ReportPage from './pages/Reports';
import { LicenseInfo } from '@mui/x-license-pro';
import config from './config';
import ProtectedRoute from './components/ProtectedRoute';
import Navigation from './components/Navigation';
import { useAuth } from './contexts/AuthContext';
/* import { useAxiosSetup } from './hooks/useAxiosSetup'; */
import { useAppContext } from './contexts/AppContext';
import ProgressScreen from './pages/ProgressScreen';
// TODO: set license in env var: process.env.NEXT_PUBLIC_MUI_X_LICENSE_KEY
LicenseInfo.setLicenseKey(config.mui_license);

// Component to conditionally render NavDrawer
const ProtectedNavigation = () => {
  const { isLoggedIn, currentTenant } = useAuth(); // Use the authentication context
  if (isLoggedIn && currentTenant) {
      return <Navigation />
  } else {
    return null; // Don't render NavDrawer if not logged in
  }
};

const App = () => {
  const { isLoadingUserData, userDataError } = useAppContext(); 

  //useAxiosSetup(); // Set up Axios with Auth token

  if (isLoadingUserData) {
    // Display progress screen while userData is loading
    return <ProgressScreen />;
  } 
  
  if (userDataError) {
    // Redirect to login page if there was an error fetching userData
    return <Navigate to="/login" replace />;
  }
  
  return (
    <div className="App">
            <ProtectedNavigation />
            <Routes>
              <Route path="/login" element={<LoginPage />} /> 
              <Route path="/" element={
                  <ProtectedRoute>
                    <Search />
                  </ProtectedRoute>
                } 
              />
              <Route path="/q-a" element={
                  <ProtectedRoute>
                    <QA />
                  </ProtectedRoute>
                } 
              />
              <Route path="/projects" element={
                  <ProtectedRoute>
                    <ProjectsPage />
                  </ProtectedRoute>
                } 
              />
              <Route path="/reports" element={
                  <ProtectedRoute>
                    <ReportPage />
                  </ProtectedRoute>
                } 
              />
            </Routes>
    </div>
  );


}

export default App;
