import React, { useState } from 'react';
import { Button, Grid, Typography, Chip, IconButton, TextField, Card, CardContent, 
  Divider, Box, FormControl, InputLabel, MenuItem, Select, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers';
import deLocale from 'date-fns/locale/de';
/* import { parse, format } from 'date-fns'; */
import CloseIcon from '@mui/icons-material/Close';
/* import EditableList from './ReportTemplatesEditableList'; */
import RecurringEventControls from './RecurringEventControls'; 
import MultipleFileUploader from './MultipleFileUploader';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useAppContext } from '../contexts/AppContext';

const EditDetailReportTemplate = ({ editData, handleEditChange, isViewAction, handleLocalSaveClick, onBackClick }) => {
  const [status, setStatus] = useState(editData.status || 'inactive');

  const toggleStatus = () => {
    setStatus(status === 'active' ? 'inactive' : 'active');
  };

  const { fetchReportData, createReportInstance, userData } = useAppContext();    

  const VerticalDivider = styled(Divider)(({ theme }) => ({
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  }));

/*   const theme = useTheme(); */

  const projects = [
      { id: '1', name: 'Project A' },
      { id: '2', name: 'Project B' },
      { id: '3', name: 'Project C' },
  ];

  const response_formats = [
    "json_object",
    "text",
  ];

  const models = [
    "gpt-4-turbo-preview",
    "gpt-3.5-turbo-0125",
  ];

  // EditDetailReportTemplate component
  const handleFileChangeForReferenceDocuments = (files) => {
    // Update the editData state with the new list of reference documents
    handleEditChange('reference_documents', files);
   /*  setFiles(files); */
  };
  

  // Handles changes specifically for the project dropdown, assuming the project structure is { id: '', name: '' }
  const handleProjectChange = (event) => {
    const projectId = event.target.value;
    const selectedProject = projects.find(project => project.id === projectId);
    handleEditChange('project', selectedProject); // Assuming your state management can handle nested updates directly
  };

  const triggerCreateReport = async () => {
    console.log('Scenario Ausführen');
    //fetchReportData(editData.title, editData.instructions, editData.model, editData.response_format, editData.reference_documents);
    try {
        // Directly await the promise returned by postCreateReport
        const response = await fetchReportData(editData.title, editData.model, editData.response_format, editData.reference_documents, editData.system_instructions, editData.user_instructions);
        console.log("Fetched Report data for creation: ", response);
        if(response != null) {
          createReportInstance(response);
        }
        // Return the response so it can be used by the caller of fetchReportData
        return response;
    } catch (error) {
        // Log and re-throw the error to allow the caller to handle it
        console.error(error);
        throw error;
    }
  };

  function canAccessSystemSettings(user) {
    return user.email.endsWith('@nukleus.ch');
  }

  const hasAccess = canAccessSystemSettings(userData);

  return (
    <div style={{ padding: '20px' }}>
      <Grid container spacing={2} direction="column">
        
        {/* First Row */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs>
      
                  <Typography variant="h6" align="left">Planung</Typography>
                  <TextField
                    label="Titel"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={editData.title}
                    onChange={(e) => handleEditChange('title', e.target.value)}
                  />
                  <TextField
                    label="Beschreibung"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={editData.description}
                    onChange={(e) => handleEditChange('description', e.target.value)}
                  />
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Projekt</InputLabel>
                    <Select
                      value={editData.project?.id}
                      onChange={handleProjectChange}
                      label="Projekt"
                    >
                      {projects.map((project) => (
                        <MenuItem key={project.id} value={project.id}>{project.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 2, marginBottom: 2 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                      <DatePicker
                        label="Startdatum"
                        value={editData.start_date}
                        onChange={(newValue) => handleEditChange('start_date', newValue)}
                        renderInput={(params) => <TextField {...params} sx={{ width: '48%' }} />}
                      />
                      <DatePicker
                        label="Enddatum"
                        value={editData.end_date}
                        onChange={(newValue) => handleEditChange('end_date', newValue)}
                        renderInput={(params) => <TextField {...params} sx={{ width: '48%' }} />}
                      />
                    </LocalizationProvider>
                  </Box>

                  <Box display="flex" alignItems="center" marginTop={2}>
                    <Typography variant="body1" component="span" marginRight={1}>Status:</Typography>
                    <Chip label={status} onClick={toggleStatus} color={status === 'active' ? 'success' : 'default'} clickable />
                  </Box>

                  <Typography variant="body2" marginTop={2} align="left">Last used: {'18.02.2024'}</Typography>
                  <RecurringEventControls />
                </Grid>

                <VerticalDivider orientation="vertical" flexItem />

                <Grid item xs>
                  <Accordion disabled={!hasAccess}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="system-settings-content" id="system-settings-header">
                      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="h6">System Einstellungen</Typography>
                        <Typography variant="subtitle1">Zugriff auf Systemeinstellungen</Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid item xs>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Modell</InputLabel>
                          <Select
                            value={editData.model}
                            onChange={(e) => handleEditChange('model', e.target.value)}
                            label="Modell"
                          >
                            {models.map((model) => (
                              <MenuItem key={model} value={model}>{model}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <FormControl fullWidth margin="normal">
                          <InputLabel>Ausgabe</InputLabel>
                          <Select
                            value={editData.response_format}
                            onChange={(e) => handleEditChange('response_format', e.target.value)}
                            label="Ausgabe"
                          >
                            {response_formats.map((format) => (
                              <MenuItem key={format} value={format}>{format}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Box sx={{ marginTop: 2 }}>
                          <Typography variant="subtitle1" align="left" gutterBottom>
                            System Anweisungen
                          </Typography>
                          <TextField
                            label="Anweisungen"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={13}
                            value={editData.system_instructions}
                            onChange={(e) => handleEditChange('system_instructions', e.target.value)}
                          />
                        </Box>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                  
                  <Grid item xs>
                    <Card>
                      <CardContent>
                        <MultipleFileUploader sx={{marginTop:2}} editData={editData} onFilesChange={handleFileChangeForReferenceDocuments} />

                        <Box sx={{marginTop:2}}>
                          {/* Title */}
                          <Typography variant="h6" align="left" gutterBottom>
                            Anweisungen
                          </Typography>

                          {/* Subtitle */}
                          <Typography variant="subtitle1" align="left" gutterBottom>
                            Detaillierte Schritte und Richtlinien für die Durchführung des Scenarios.
                          </Typography>

                          {/* TextField modified for large texts */}
                          <TextField
                            label="Anweisungen"
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={13}
                            value={editData.user_instructions}
                            onChange={(e) => handleEditChange('user_instructions', e.target.value)}
                          />
                        </Box>
                      </CardContent>
                    </Card>
                    
                  </Grid>

                </Grid>

              </Grid>
            </CardContent>
          </Card>
        </Grid>

         <Grid item xs={12} style={{ textAlign: 'right' }}>
      
            {/* <Button variant="contained" onClick={triggerCreateReport}>Scenario Ausführen</Button> */}
                
            <Button 
              variant="contained" 
              color="primary" 
              disabled={isViewAction}
              onClick={handleLocalSaveClick} // Use the local handler 
              style={{ margin: '8px' }}>
              Speichern
            </Button>
            <IconButton onClick={onBackClick}>
              <CloseIcon />
            </IconButton>
          </Grid>

      </Grid>
    </div>
  );
};

export default EditDetailReportTemplate;

