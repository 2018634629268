import React, {useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { List, Box, Button, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, Divider, IconButton, Dialog, DialogActions, DialogContent } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import SearchIcon from '@mui/icons-material/Search';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import AssessmentIcon from '@mui/icons-material/Assessment';
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SettingsIcon from '@mui/icons-material/Settings';

import ReactMarkdown from 'react-markdown';

import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useAppContext } from '../contexts/AppContext';
import { useAuth } from '../contexts/AuthContext';
import NavBar from './NavigationBar';
import productPlan from '../PRODUCTPLAN_DE.md';
import config from '../config';

const drawerWidth = config.ui_settings.drawer_width;//240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'drawerOpen' })(
  ({ theme, drawerOpen }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(drawerOpen && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!drawerOpen && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function Navigation() {
  const { drawerOpen, setDrawerOpen, setActivePage, setPageTabs, setActiveTab, getProjectDetailList, qadocList, getReportTemplatesList, getReportInstancesList, userProjects } = useAppContext();
  const { currentTenant } = useAuth();
  const [logo, setLogo] = React.useState('nukleus');
  const [markdown, setMarkdown] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [client_id, setClientId] = React.useState(null);
  const [localSearchParams, setLocalSearchParams] = useSearchParams(); // set with object { key: value }
  const [selectedIndex, setSelectedIndex] = React.useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const location = useLocation();
  const navigate = useNavigate();
  const theme = useTheme();


  const menuItems_front_end = [/* 'Dashboard (POC2)',  */'Suche', 'Projekte', 'Berichte (WIP)', 'Q&A (WIP)',  /*  'Aufgaben (WIP)', 'Themen' */];
  const menuItems = ['home', /* 'search',  */ 'projects','reports', 'q-a', /* 'tasks', 'topics' */];
  const subMenuItems = ['Produktplan', 'Feedback melden', 'Einstellungen'];
  const links = ['', 'https://forms.office.com/e/ZruJY1sAkB', '']
  
  const seralizeParams = () => {
    let result = '';
    for(let entry of localSearchParams.entries()) {
      result+= entry[0] + '=' + entry[1];
    }
    return result;
  }

  const redirectRoute = routePath => {
    navigate({ pathname: routePath, search: seralizeParams() });
  };
  
  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  const handleMenuItemClick = (index) => { 
    setSelectedIndex(index);
    console.log('clear results!')
    if(index === 0) {
        redirectRoute("/");
    } else {
      let item= menuItems[index];
      if(item) {
        let path = "/" + item.toLowerCase();
        redirectRoute(path);

        // loading projects on menu change
        if(index == 1) {
          getProjectDetailList(currentTenant, userProjects);
        }

        if(index == 2) {
          getReportTemplatesList();
          getReportInstancesList();
        }
      }
    }
  };

  // Logo ---------------------------------------
  const setLogoFromClientID = async () => {
      if(client_id === null) {
        setLogo('nukleus');
      } else {
        const response = await fetch(`./${client_id}.png`);
        const file = await response.blob();
            
        if(file.size > 0 && file.type === 'image/png') {
            setLogo(client_id);
        } else {
            setLogo('nukleus');
        }
      }
  };

  // UseEffects ---------------------------------------
  useEffect(() => {
      /* console.log("SearchParams: tab_id:", localSearchParams.get('tab_id'));
      console.log("SearchParams: page:", localSearchParams.get('page')); */
      setClientId(localSearchParams.get('client_id'));
      }, 
      []
    );
  useEffect(() => {
      /* console.log("NavDrawer: setLogoFromClientID for client_id: ", client_id); */
      setLogoFromClientID();
      fetch(productPlan).then((response) => response.text()).then((text) => {
        setMarkdown(text);
      })

      }, 
      [client_id]
  );

  const tabs = {
    'login':[],
    'home':[], //['Aufgaben', 'Themen'],
    /* 'search':['Projekte', 'Vorgaben und Vorlagen'], */
    'q-a':['Zwischenlagerung'],
    'projects':[],
    'topics':[],
    'tasks':[],
    'reports':['Vorlagen', 'Historie']
  }

  // Todo: if url params exist, they should override tab settings
  useEffect( () =>  {
    let loc = location.pathname.split('/')[1];
    /* console.log('Location changed: ', location.pathname);
    console.log('params changed: ', localSearchParams); */
    
    if(loc === '') {
      setPageTabs(tabs['home']);
      setActivePage(0);
      setActiveTab(0);
      setSelectedIndex(0);

      /* console.log('ActivePageIndex', 0); */
    } else {
      setPageTabs(tabs[loc]);
      let activePageIndex = menuItems.indexOf(loc);
      setActivePage(activePageIndex);

      if(loc === 'q-a') {
        qadocList()
      }

      /* console.log('ActivePageIndex', activePageIndex); */

    }
  }, [location.pathname]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <NavBar position="fixed" open={drawerOpen} handleDrawerOpen={handleDrawerOpen} selectedIndex={selectedIndex} menuItems_front_end={menuItems_front_end}/>
      <Drawer variant="permanent" drawerOpen={drawerOpen} sx={{display:'flex'}}>
        <DrawerHeader>
            <img src={'./' + logo + '.png'} height={35} sx={{marginRight:'50px !important'}} alt="logo" />
          <IconButton onClick={handleDrawerClose} sx={{marginLeft:'30px'}}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems_front_end.map((text, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                key={text}
                /* disabled={index != 2 } */
                disabled={index > config.envs[config.active_env].modules }
                selected={index === selectedIndex}
                onClick={() => handleMenuItemClick(index)}
                sx={{
                  minHeight: 48,
                  justifyContent: drawerOpen ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  { index === 0 ? <SearchIcon /> : 
                    index === 1 ? <FolderCopyIcon /> : 
                    index === 2 ? <LightbulbIcon /> : 
                    index === 3 ? <QuestionAnswerIcon /> : 
                    index === 4 ? <LightbulbIcon /> : 
                    index === 5 ? <AssignmentTurnedInIcon /> : <AssessmentIcon /> }
                    {/* index === 1 ? <SearchIcon /> : 
                    index === 2 ? <QuestionAnswerIcon /> : 
                    index === 3 ? <FolderCopyIcon /> : 
                    index === 4 ? <LightbulbIcon /> : 
                    index === 5 ? <AssignmentTurnedInIcon /> : <AssessmentIcon /> } */}
                  
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: drawerOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {subMenuItems.map((text, index) => (
            <ListItem key={index} disablePadding sx={{ display: 'block' }}>
              <ListItemButton
                component={(links[index].length > 0)?"a":''}
                target='_blank'
                disabled={index > 1}
                href={links[index]}
                key={text} 
                onClick={index===0?handleOpen:null}
                sx={{
                  minHeight: 48,
                  justifyContent: drawerOpen ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: drawerOpen ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  { index === 0 ? <FlightTakeoffIcon /> : 
                    index === 1 ? <FeedbackIcon /> : <SettingsIcon /> }
                </ListItemIcon>
                <ListItemText primary={text} sx={{ opacity: drawerOpen ? 1 : 0 }} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Typography variant='button' sx={{fontSize:'0.70rem', bottom:0, display:drawerOpen?'initial':'none'}}>Version 0.1.13 (Alpha)</Typography>
      </Drawer>
      
      <Dialog
        fullWidth={true}
        maxWidth={false}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <ReactMarkdown children={markdown} />
        </DialogContent>
    
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}